import { useContext, useState, useEffect, useRef } from 'react'
import { ReactComponent as Close2Icon } from '../assets/images/close2-icon.svg'
import { get ,post } from '../libs/http-hydrate'
import Auth from '../libs/auth'
import { useNavigate } from 'react-router-dom';
import ReactSelect, { components } from "react-select";
import Select from "react-select";
import { UseEffectOnce } from '../Hook/UseEffectOnce';

function RequireProductsCategories (props) {

  const [categoryData, setCategoryData] = useState(
    {
    items:[],
    has_more:false
    }
  );
  const [CategoryPage, setCategoryPage]= useState(0);
  const [userSelectedProductCategory, setUserSelectedProductCategory] = useState(
    {
      items:{}
    }
  )
  const [categoryIds, setCategoryIds] = useState([]);
  const [categoryValue, setCategoryValue] = useState('')
  const navigate = useNavigate();
  const user = Auth.getCurrentUser()
  useEffect(() => {
    if (!user) {
      navigate('/')
    }
      post('/general/category_listing',{type:'product'})
      .then((response)=> {
        setCategoryData((p) => ({
          ...p,
          items:response?.data?.data,
        }));
        setCategoryData(response.data.data);
        setCategoryData((p) => ({...p , has_more :response?.data?.has_more}));
      })
      .catch((err)=>{
        if(err) {
          alert("Something went wrong")
        }
      })
       handleCategoryValue(userSelectedProductCategory)
  },[userSelectedProductCategory])

  UseEffectOnce(()=>{
    setCategoryIds(props?.data)
  },[])

  useEffect(()=> {

    if(CategoryPage === 0 ){

    }
    else{
      CallAPI();
    }
  },[CategoryPage])

  const availableCategoryData = categoryData?.items;
  const handleAddCategory = (e)=> {
    e.preventDefault();

    let filter = catRef?.current?.getValue();
    let data =[];
    filter.map((item,index) =>{

      var t = categoryIds.filter(function (el) {
        return el.id === item?.value || el.value === item?.value
      });

      if(t.length >= 1){

      }
      else{
        data?.push(item)

      }
     
    })
    setCategoryIds(categoryIds.concat(data))
  }
  console.log(categoryIds,"categoryIds")
  const handleDeleteCategory = (id)=>{

    // alert(id)
    // let arr = categoryIds;
    // arr.splice(id,1)
    setCategoryIds(currentImg => currentImg.filter((img, i) => i !== id));

  }



  async function CallAPI(){
    post('/general/category_listing',{type:'product',page:CategoryPage + 1})
    .then((response)=> {

      if(CategoryPage === 0){
        setCategoryData((p) => ({
          ...p,
          items: response?.data?.data,
        }));

      }
      else{
        setCategoryData((p) => ({
          ...p,
          items: categoryData.items.concat(response?.data?.data),
        }));

      }
      setCategoryData((p) => ({...p , has_more :response?.data?.has_more}))
      

    })
    .catch((err)=>{
      if(err) {
        alert("Something went wrong")
      }
    })
  }

  const handleSaveCategory = ()=> {

    

    const newArray = [];
    categoryIds.forEach((select) => {
      if(select?.id){
        if (!newArray.includes(select.id)) {
          newArray.push(select.id);
        }
      }
      else{
        if (!newArray.includes(select.value)) {
          newArray.push(select.value);
        }
      }
    
    });

    post('/user/profile/sync_product_category', {category_ids:newArray}, {headers:{'Authorization':`Bearer ${user.token}`}})
    .then((response)=>{
      if (response.status === 200) {
        window.location.reload();
      }
    })
    .catch((e)=>{
      if (e.response.status === 400) {
        alert(`${e.response.data.message}`)
      }
      if (e.response.status === 404) {
        alert("Route Not Found")
      }
      if (e.response.status === 500) {
        alert("Internal Server Error")
      }
    })
  }

  const  handleCategoryValue =  (categoryId) => {
    categoryData.items.forEach((obj)=> {
      if(obj.id == categoryId) {
        setCategoryValue(obj.name)
      }
  })
  }
  const transformedIndustryArray =  categoryData?.items && categoryData?.items.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={(e) =>{
              e.preventDefault()
            }}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };


  const handleIndustryChange = (selected) => {
      // setOptionSelected(selected);
    const newArray = [];
    selected.forEach((select) => {
      if (!newArray.includes(select.value)) {
        newArray.push(select.value);
      }
    });
    setUserSelectedProductCategory(newArray);
  };

  const catRef = useRef(null);



  useEffect(() => {
  

});
  return (
        <>
<div className="modal fade certifications-modal add-product-category" id="ProductCategoryModal" tabindex="-1" role="dialog" aria-labelledby="ProductCategoryModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Require Products Categories</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><Close2Icon /></span>
                </button>
              </div>
              <div className="modal-body">
                <div className='tags tags-regular'>
                  {categoryIds.length > 0 ?
                   <>
                   {categoryIds.map((category,index)=> (
                  <span className='tag' >{category?.name ? category?.name : category?.label }<Close2Icon onClick={()=> handleDeleteCategory(index)} /></span>
                  ))}
                   </> : <></>}
                </div>
                <div className='form-field'>
                <label className='form-label'>Add Category</label>
                <div className='flex-box row'>



                <Select
                ref={catRef}
                     className="col-8"

                        placeholder="Select Category"
                        id="category"
                        onFocus={(e) => {
                          e.preventDefault();
                          CallAPI();
                        }}
                        isMulti={true}
                        options={ categoryData?.items && categoryData?.items.map(function (
                          productArray
                        ) {
                          return {
                            value: productArray.id,
                            label: productArray.name,
                          };
                        })}
                        onKeyDown={(e) => {
                        }}
                        onChange={(e) => {
                        }}
                        onBlur={(e) => {
                          e.preventDefault();
                        }}
                        onMenuScrollToBottom={(e) => {
                          if (categoryData?.has_more === true) {
                            let p = CategoryPage + 1;
                            setCategoryPage(p);
                          }
                        }}
                       
                      ></Select>
              
                {/* <select name='cname' id='cname' className='form-input' value={userSelectedProductCategory} onChange={(e)=> {setUserSelectedProductCategory(e.target.value)}}>
                  <option disabled selected value=''>Select Your Product Category</option>
                {availableCategoryData.map((categoryData) => (
              <option value={categoryData.id}>{categoryData.name}</option>
              ))}
                </select> */}
              <button className='button button-blue col-3'
              
               onClick={(e)=> handleAddCategory(e)}
               
               >Add</button>
                </div>
              </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="button button-primary" onClick={()=> handleSaveCategory()}>Save</button>
              </div>
            </div>
          </div>
        </div>
        </>
  )
}

export default RequireProductsCategories