import React from "react";
import { ReactComponent as RecommendationIcon } from "../../assets/images/recommendation-new.svg";
import ReviewProgressPanel from "./ReviewProgressPanel";

const ReviewCard = ({ isSeller, reviews }) => {
  return (
    <div className="card">
      <div className="card-header card-header--large">
        <h6>
          <RecommendationIcon />
          Recommendation
        </h6>
      </div>
      <div className="card-body">
        <ReviewProgressPanel
          reviews={isSeller === 0 ? Number(reviews?.respond) : Number(reviews?.price)}
          label={isSeller === 0 ? "Respond" : "Price"}
        />
        <ReviewProgressPanel
          reviews={isSeller === 0 ? Number(reviews?.payment) : Number(reviews?.quality)}
          label={isSeller === 0 ? "Payment" : "Quality"}
        />
        <ReviewProgressPanel
          reviews={isSeller === 0 ? Number(reviews?.valuable) : Number(reviews?.service)}
          label={isSeller === 0 ? "Valuable" : "Service"}
        />
        <ReviewProgressPanel
          reviews={Number(reviews?.support)}
          label={"Support"}
        />
      </div>
    </div>
  );
};

export default ReviewCard;
