import React from 'react'

const StepItem = ({indicator, label, tag, isCompleted, isActive}) => {

  const stepCompleted = indicator <= (Number(isCompleted))
  
    const stepClass = isActive ? 'step active' : stepCompleted ? 'step completed' : 'step'
  
    return (
    <li className={stepClass}>
      <div className='step-indicator'>{indicator}</div>
      <div className="step-label">{label}</div>
      <div className="step-tag">{tag}</div>
    </li>
  )
}

export default StepItem