import notificationConstants from "../constants/notificationConstants";
import orderStatus from "../constants/orderConstants";
import { get, getAuthConfig } from "../libs/http-hydrate";
import negotiationConstants from "../constants/negotiatonOrderStatus";
import { useContext } from "react";

const userType = localStorage.getItem("buyerAccount");

export const redirectNotification = async (notification) => {
  const { type, transaction_id, negotiation_id, item, title } = notification;

  if (type === notificationConstants.TRANSACTION) {
    try {
      const transactionDetail = await get(
        `/short_order/details/${transaction_id}`,
        getAuthConfig()
      );
      const status = transactionDetail?.data?.data?.status;
      return handleRedirection(status);
    } catch (error) {
      console.log(error);
    }
  } else if (type === notificationConstants.NEGOTIATION) {
    try {
      const negotiationDetail = await get(
        `/negotiation/details/${negotiation_id}`,
        getAuthConfig()
      );
      const status = negotiationDetail?.data?.data?.status;
      const orderType = negotiationDetail?.data?.data?.type;
      return handleNegotion(status, negotiation_id, orderType);
    } catch (error) {
      console.log(error);
    }
  } else if (type === notificationConstants.REACTION) {
    if (item?.post_type === "social_post") {
      return `/post-detail/${item?.slug}/${"social_post"}`;
    } else {
      return `/post-detail/${item?.id}/${"post"}`;
    }
  } else if (type === notificationConstants.SOCIAL_POST_CARD) {
    return `/post-detail/${item?.slug}/${"social_post"}`;
  } else if (type === notificationConstants.COMMENT) {
    if (item?.post_type === "social_post") {
      return `/post-detail/${item?.slug}/${"social_post"}`;
    } else {
      return `/post-detail/${item?.id}/${"post"}`;
    }
  } else if (type === notificationConstants.CONNECTION_REQUEST) {
    console.log("first");
    if (
      type === notificationConstants.CONNECTION_REQUEST &&
      title.includes("Accepted")
    ) {
      return `/my-network`;
    } else {
      return "/received-request";
    }
  }
};

export const handleRedirection = (status) => {
  let redirect;
  switch (status) {
    case orderStatus.OFFER_SENT:
      redirect = "/product-short-order";
      break;
    case orderStatus.OFFER_ACCEPTED:
      redirect = "/product-company-detail";
      break;
    case orderStatus.OFFER_REJECTED:
      redirect = "/product-short-order";
      break;
    case orderStatus.PURCHASE_ORDER:
      redirect = "/product-purchase-order";
      break;
    case orderStatus.INVOICE:
      redirect = "/product-purchase-invoice";
      break;
    case orderStatus.E_WAY_BILL:
      redirect = "/product-e-bill";
      break;
    case orderStatus.MATERIAL_DISPATCH:
      redirect = "/product-material-dispatch";
      break;
    case orderStatus.DELIVERED:
      redirect = "/product-delivered";
      break;
    case orderStatus.BANK_DETAILS:
      redirect = "/product-bank-detail";
      break;
    case orderStatus.PAYMENT:
      redirect = "/product-payment";
      break;
    case orderStatus.COMPLETED:
      redirect = "/product-rating";
      break;
    default:
  }
  return redirect;
};

export const handleNegotion = (status, negotiation_id, orderType) => {
  let redirect;
  switch (status) {
    case negotiationConstants.REJECTED:
      redirect =
        orderType === "post_to_buy"
          ? orderType === "post_to_buy" && userType === "false"
            ? `/negotiation-buyer-adjustment-post/${negotiation_id}`
            : `/negotiation-buyer-adjustmentView-post/${negotiation_id}`
          : orderType === "post_to_sell"
          ? orderType === "post_to_sell" && userType === "false"
            ? "/deal-buyer"
            : "/deal-seller"
          : userType === "false"
          ? "/send-offer-view"
          : `/negotiation-buyer-adjustmentView/${negotiation_id}`;
      break;
    case negotiationConstants.INITIAL_OFFER:
      redirect =
        orderType === "post_to_buy"
          ? orderType === "post_to_buy" && userType === "false"
            ? "/deal-buyer"
            : `/negotiation-buyer-adjustmentView-post/${negotiation_id}`
          : orderType === "post_to_sell"
          ? "/deal-buyer"
          : "/send-offer";
      break;
    case negotiationConstants.FINAL_OFFER:
      redirect =
        orderType === "post_to_sell"
          ? orderType === "post_to_sell" && userType === "false"
            ? "/deal-buyer"
            : "/deal-seller"
          : userType === "false"
          ? "/send-offer-view"
          : `/negotiation-buyer-adjustmentView/${negotiation_id}`;
      break;
    case negotiationConstants.REVISE_OFFER:
      redirect =
        userType === "false"
          ? `/negotiation-buyer-adjustment-post/${negotiation_id}`
          : `/negotiation-buyer-adjustmentView-post/${negotiation_id}`;
      break;
    case negotiationConstants.COMPLETED:
      redirect =
        orderType === "post_to_buy"
          ? orderType === "post_to_buy" && userType === "false"
            ? `/negotiation-buyer-adjustment-post/${negotiation_id}`
            : `/negotiation-buyer-adjustment-post/${negotiation_id}`
          : orderType === "post_to_sell"
          ? orderType === "post_to_sell" && userType === "false"
            ? "/deal-buyer"
            : "/deal-seller"
          : userType === "false"
          ? "/deal-seller"
          : `/negotiation-buyer-adjustmentView/${negotiation_id}`;
      break;
    default:
  }
  return redirect;
};
