import React, { useCallback, useEffect, useRef, useState } from "react";
import "../common/scss/pages/short-order.scss";
import Header from "../common/header";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import InCompleteIcon from "../assets/images/short-incomplete.svg";
import RejectedOfferIcon from "../assets/images/rejeted-offer.svg";
import DraftOfferIcon from "../assets/images/draft-offer.svg";
import { ReactComponent as AcceptIcon } from "../assets/images/offer-accept.svg";
import { ReactComponent as RejectIcon } from "../assets/images/short-reject.svg";
import ShortOngoingIcon from "../assets/images/short-ongoing-icon.svg";
import ShortCompletedIcon from "../assets/images/short-comleted.svg";
import { Link, useLocation } from "react-router-dom";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import moment from "moment";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import Auth from "../libs/auth";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import orderStatus from "../constants/orderConstants";
import { PuffLoader } from "react-spinners";

function Transaction() {
  const [orderListing, setOrderListing] = useState({
    has_more: false,
    items: [],
  });
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState("latest");
  const [filtering, setFiltering] = useState("all");
  const [typeFilter, setTypeFilter] = useState("");
  const [searching, setSearching] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = (action) => {
    setPage(0);
    setFiltering(action);
  };

  const handleClickFilter = (action) => {
    setTypeFilter(action);
  };

  const user = Auth.getCurrentUser();

  const shortOrderListing = async () => {
    setLoading(true);

    if(searching !== ""){
      setPage(0)
    }

    try {
      const response = await get(
        `/short_order/listing?page=${page + 1}&type=${filtering}&order_by=${sorting}&search=${searching || ''}&from=${typeFilter}`,
        getAuthConfig()
      );

      setLoading(false);

      if (page === 0) {
        setOrderListing((p) => ({
          ...p,
          items: response?.data?.data?.data.length > 0 ? response?.data?.data?.data : [],
        }));
      } else {
        setOrderListing((p) => ({
          ...p,
          items: page === 0 ? (response?.data?.data?.data || []) : orderListing.items.concat(response?.data?.data?.data || []),
        }));
      }

      setOrderListing((p) => ({
        ...p,
        has_more: response?.data?.data?.has_more || false,
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const location = useLocation();

  const onTop = () => {
    window.scrollTo(0, 0);
  };
  UseEffectOnce(() => {
    onTop();
  }, [location]);

  const searchShortOrder = (e) => {
    setSearching(e.target.value);
  };

  useEffect(() => {
    shortOrderListing();
  }, [sorting, filtering, searching, typeFilter, page]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && orderListing?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [orderListing?.has_more]
  );

  const handleAcceptOrReject = async (action, id, index) => {
    const formData = new FormData();
    formData.append("type", action);
    formData.append("order_id", id);
    await post("/short_order/action_on_transaction", formData, getAuthConfig())
      .then((res) => {
        if (action === "reject") {
          toast.success("Offer Rejected Successfully ");
        }

        let arr = [];
        arr = orderListing?.items;
        arr[index].status = orderStatus.OFFER_REJECTED;

        setOrderListing((p) => ({ ...p, items: arr }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
    shortOrderListing();
  };

  useEffect(() => {
    if (
      location?.state?.filtering != undefined ||
      location?.state?.filtering != null
    ) {
      setTimeout(() => {
        setFiltering(location?.state?.filtering);
      }, 300);
    }
  }, [location?.state?.filtering]);

  const parentToChild = (slug) => {
    if (page === 0) {
      shortOrderListing();
    } else {
      setPage(0);
    }
  };

  const stepName = (item) => {
    let step;
    switch (item?.step?.current_step) {
      case "1":
        step = " ( Short Order )";
        break;
      case "2":
        step = " ( Company Details )";
        break;
      case "3":
        step = " ( Purchase Order )";
        break;
      case "4":
        step = " ( Invoice )";
        break;
      case "5":
        step =
          item?.payment_type === "advance"
            ? " ( Bank Details )"
            : " ( E-way Bill )";
        break;
      case "6":
        step =
          item?.payment_type === "advance"
            ? " ( Payment )"
            : " ( Material Dispatch )";
        break;
      case "7":
        step =
          item?.payment_type === "advance"
            ? " ( E-way Bill )"
            : " ( Delivered )";
        break;
      case "8":
        step =
          item?.payment_type === "advance"
            ? " ( Material Dispatch )"
            : " ( Bank Details )";
        break;
      case "9":
        step =
          item?.payment_type === "advance" ? " ( Delivered )" : " ( Payment )";
        break;
      case "10":
        step = " ( Rating )";
        break;
      default:
        step = "";
        break;
    }
    return step;
  };

  const stepRoute = (item) => {
    let navigate;
    switch (item?.status) {
      case orderStatus.PURCHASE_ORDER:
        navigate = "/product-purchase-order";
        break;
      case orderStatus.INVOICE:
        navigate = "/product-purchase-invoice";
        break;
      case orderStatus.BANK_DETAILS:
        navigate = "/product-bank-detail";
        break;
      case orderStatus.PAYMENT:
        navigate = "/product-payment";
        break;
      case orderStatus.E_WAY_BILL:
        navigate = "/product-e-bill";
        break;
      case orderStatus.MATERIAL_DISPATCH:
        navigate = "/product-material-dispatch";
        break;
      case orderStatus.DELIVERED:
        navigate = "/product-delivered";
        break;
      case orderStatus.OFFER_ACCEPTED:
        navigate = "/product-company-detail";
        break;
      case orderStatus.OFFER_REJECTED:
        navigate = "/product-short-order";
        break;
      case orderStatus.OFFER_SENT:
        navigate = "/product-short-order";
        break;
      case orderStatus.COMPLETED:
        navigate = "/product-rating";
        break;
      case orderStatus.CANCELLED:
        navigate = "/product-short-order";
        break;
      default:
        navigate = "/transaction";
        break;
    }
    return navigate;
  };

  const stepStatus = (item) => {
    let status;
    switch (item?.status) {
      case orderStatus.COMPLETED:
        status = (
          <>
            <img src={ShortCompletedIcon} alt="statuslogo" />
            <span className="status-label">Complete</span>
          </>
        );
        break;
      case orderStatus.CANCELLED:
        status = (
          <>
            <img src={InCompleteIcon} alt="statuslogo" />
            <span className="status-label">Transaction Incomplete</span>
          </>
        );
        break;
      case orderStatus.OFFER_REJECTED:
        status = (
          <>
            <img src={RejectedOfferIcon} alt="statuslogo" />
            <span className="status-label">Transaction Rejected</span>
          </>
        );
        break;
      case orderStatus.DRAFT:
        status = (
          <>
            <img src={DraftOfferIcon} alt="statuslogo" />
            <span className="status-label">Draft</span>
          </>
        );
        break;
      case orderStatus.TEMPORARY:
      case orderStatus.OFFER_SENT:
      case orderStatus.OFFER_ACCEPTED:
      case orderStatus.PURCHASE_ORDER:
      case orderStatus.INVOICE:
      case orderStatus.BANK_DETAILS:
      case orderStatus.PAYMENT:
      case orderStatus.E_WAY_BILL:
      case orderStatus.MATERIAL_DISPATCH:
        status = (
          <>
            <img src={ShortOngoingIcon} alt="statuslogo" />
            <span className="status-label">Ongoing SO</span>
          </>
        );
        break;
      default:
        status = <></>;
        break;
    }
    return status;
  };

  return (
    <>
      <ToastContainer />
      <Header home parentToChild={parentToChild} />

      <div className="grey-bg">
        <div className="container-fluid support-page">
          <div className="short-order">
            <div className="short-order-head">
              <div className="page-title">
                <h6>Transaction</h6>
              </div>
              <div className="order-filter">
                <div className="tabs tabs--solid">
                  <ul>
                    <li
                      className={`${typeFilter === "" ? "active" : ""}`}
                      onClick={() => handleClickFilter("")}
                    >
                      <Link to={""}>All</Link>
                    </li>
                    <li
                      className={`${typeFilter === "post" ? "active" : ""}`}
                      onClick={() => handleClickFilter("post")}
                    >
                      <Link to={""}>From Post</Link>
                    </li>
                    <li
                      className={`${
                        typeFilter === "portfolio" ? "active" : ""
                      }`}
                      onClick={() => handleClickFilter("portfolio")}
                    >
                      <Link to={""}>From Portfolio</Link>
                    </li>
                  </ul>
                </div>
                <div className="search-box">
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Search"
                    value={searching}
                    onChange={searchShortOrder}
                    onBlur={(e) => {
                      e.preventDefault();
                      clearInterval();
                    }}
                  />
                  <button className="search-button">
                    <SearchIcon />
                  </button>
                </div>
              </div>
              <div className="short">
                <select
                  className="form-input"
                  value={sorting}
                  onChange={(e) => setSorting(e.target.value)}
                >
                  <option value="">Sort By</option>
                  <option value="latest">Newest</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>
            </div>
            <div className="tabs tabs--transparent">
              <ul>
                <li
                  className={`${filtering === "all" ? "active" : ""}`}
                  onClick={() => handleClick("all")}
                >
                  <Link to={""}>All</Link>
                </li>
                <li
                  className={`${filtering === "on_going" ? "active" : ""}`}
                  onClick={() => handleClick("on_going")}
                >
                  <Link to={""}>Ongoing</Link>
                </li>
                <li
                  className={`${filtering === "completed" ? "active" : ""}`}
                  onClick={() => handleClick("completed")}
                >
                  <Link to={""}>Completed</Link>
                </li>
                <li
                  className={`${filtering === "in_complete" ? "active" : ""}`}
                  onClick={() => handleClick("in_complete")}
                >
                  <Link to={""}>Incomplete</Link>
                </li>
                <li
                  className={`${filtering === "draft" ? "active" : ""}`}
                  onClick={() => handleClick("draft")}
                >
                  <Link to={""}>Draft</Link>
                </li>
                <li
                  className={`${filtering === "rejected" ? "active" : ""}`}
                  onClick={() => handleClick("rejected")}
                >
                  <Link to={""}>Rejected</Link>
                </li>
              </ul>
            </div>
            {loading && (
              <div className="d-flex justify-content-center">
                <PuffLoader size={100} />
              </div>
            )}

            {!orderListing?.items?.length && !loading ? (
              <>No Data Found</>
            ) : (
              <>
                {orderListing?.items?.map((item, iindex) => (
                  <div className="orders" ref={lastBookElementRef} key={iindex}>
                    <div className="order-item">
                      <div className="image-block">
                        <img
                          src={item?.products[0]?.thumb_image?.file}
                          alt=""
                        />
                      </div>
                      <div className="content-block">
                        <div className="left">
                          <h6>
                            {item?.products?.length > 1 ? (
                              <Link
                                to={"/product-short-order"}
                                state={{ id: item?.id }}
                              >
                                {item?.products[0]?.name +
                                  " " +
                                  ` & view ${item?.products?.length - 1} more `}
                              </Link>
                            ) : (
                              <>
                                {item?.products[0]?.name}{" "}
                                {item?.products?.length > 1
                                  ? ` & view ${
                                      item?.products?.length - 1
                                    } more `
                                  : ""}
                              </>
                            )}
                          </h6>
                          <div className="company-name">
                            <img
                              src={
                                user?.id === item?.to_user?.id
                                  ? item?.user?.company?.logo
                                  : item?.to_user?.company?.logo
                              }
                              alt="companyLogo"
                            />
                            {user?.id === item?.to_user?.id
                                  ? item?.user?.company?.name
                                  : item?.to_user?.company?.name}
                          </div>
                          <ul>
                            <li>
                              <label>Description</label>:
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.products[0]?.description
                                    ? item?.products[0]?.description
                                    : "N/A",
                                }}
                              ></span>
                            </li>
                            <li>
                              <label>Payment Terms</label>:
                              <span>{item?.payment_type}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="right">
                          {item.status === orderStatus.COMPLETED && (
                            <div className="time">
                              <span>Completed date: </span>
                              {moment(item?.updated_at)?.format("Do MMMM YYYY")}
                            </div>
                          )}
                          <div className="status">{stepStatus(item)}</div>
                          <div className="button-wrap">
                          {item.status !== orderStatus.COMPLETED &&
                            item.status !== orderStatus.DRAFT && (
                              <div className="step-label">
                                <span className="counter">
                                  {item?.step?.current_step}
                                </span>
                                Step - {item?.step?.current_step}
                                {stepName(item)}
                              </div>
                            )}
                          <Link
                            to={stepRoute(item)}
                            state={{ id: item?.id, route: "transaction" }}
                            className="view-btn button button-primary"
                          >
                            {" "}
                            View Post
                          </Link>

                          {item?.status === orderStatus.OFFER_SENT &&
                            user?.id === item?.to_user?.id && (
                              <div style={{ display: "flex" }}>
                                <button
                                  className="button button-green button-green--bordered btn-release"
                                  onClick={(e) => {
                                    swal({
                                      title: "Are you sure?",
                                      text: "Are you sure you want to accept this offer  ?",
                                      icon: "warning",
                                      dangerMode: true,
                                      buttons: ["Cancel", "Ok"],
                                    }).then((willDelete) => {
                                      setLoading(true);
                                      if (willDelete) {
                                        handleAcceptOrReject(
                                          "accept",
                                          item?.id,
                                          iindex
                                        );
                                        setLoading(false);
                                      } else {
                                      }
                                    });
                                  }}
                                >
                                  <AcceptIcon />
                                  Accept
                                </button>
                                <button
                                  className="button button-red button-red--bordered btn-reject"
                                  onClick={(e) => {
                                    swal({
                                      title: "Are you sure?",
                                      text: "Are you sure you want to reject this offer  ?",
                                      icon: "warning",
                                      dangerMode: true,
                                      buttons: ["Cancel", "Ok"],
                                    }).then((willDelete) => {
                                      setLoading(true);
                                      if (willDelete) {
                                        handleAcceptOrReject(
                                          "reject",
                                          item?.id,
                                          iindex
                                        );
                                        setLoading(false);
                                      } else {
                                      }
                                    });
                                  }}
                                >
                                  <RejectIcon />
                                  Reject
                                </button>
                              </div>
                            )}
                          
                          </div>
                          {item.status === orderStatus.DRAFT ? (
                            <div className="btn-wrap">
                              <Link
                                to={`/product-short-order`}
                                state={{ id: item?.id }}
                                className="button button-primary"
                              >
                                View Draft
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="order-item-footer">
                        <ul>
                          <li>
                            Transaction Start Date:{" "}
                            {moment(item?.created_at)?.format("Do MMMM YYYY")}
                          </li>
                          <li>PO ID: {item.po_id ? item.po_id : "N/A"}</li>
                          <li>
                            Invoice Number: {item.po_id ? item.po_id : "N/A"}{" "}
                          </li>
                          <li>Final Amount : ₹{item?.grand_total}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Transaction;
