import React, { useEffect } from "react";
import { ReactComponent as AttachmentIcon } from "../../assets/images/attachment.svg";
import { ReactComponent as SendIcon } from "../../assets/images/send-icon.svg";
import { useState } from "react";
import { getAuthConfig, post } from "../../libs/http-hydrate";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";

const SupportChatInput = ({
  chatHistory,
  message,
  setMessage,
  ticketIds,
  supportDetails,
  attachment,
  sortedData,
  loadingChats,
}) => {
  console.log(sortedData, "sortedData");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  console.log(sortedData.length, "pp");

  useEffect(() => {
    if (loadingChats === false) {
      console.log('first---')
      if (sortedData?.length === 0) {
        let msg = `Your subject : ${supportDetails?.subject} , title : ${
          supportDetails?.title
        } , Description:${supportDetails?.description} ${
          supportDetails?.object_id
            ? `, Order Id : ${supportDetails?.object_id}`
            : ""
        } `;
        if (supportDetails && ticketIds) {
          handleSubmit("", "", msg);
        }
      }
    }
  }, [supportDetails, loadingChats, sortedData]);

  UseEffectOnce(() => {
    if (attachment?.length > 0 && ticketIds) {
      attachment.forEach((attachment, index) => {
        handleSubmit(attachment, "file");
      });
    }
  }, [attachment]);

  const handleSubmit = async (file, type, msg) => {
    setLoading(true);
    const formData = new FormData();
    if (file && type) {
      formData.append("attachment", file);
    } else if (msg) {
      formData.append("message", msg);
    } else {
      formData.append("message", message);
    }
    try {
      const response = await post(
        `/support-ticket/${ticketIds}/chat`,
        formData,
        getAuthConfig()
      );
      if (response?.data?.status === 200) {
        setMessage("");
        chatHistory();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  console.log(ticketIds, supportDetails);
  return (
    <>
      <div className="card-footer send-message">
        <div className="chat-box">
          <input
            value={message}
            onChange={handleChange}
            type="text"
            className="form-input"
            placeholder="Type Message"
          />
          <div className="attach-btn" style={{ cursor: "pointer" }}>
            <div class="image-upload">
              <label
                for="file-input"
                style={{ cursor: "pointer", margin: "0" }}
              >
                <AttachmentIcon />
              </label>

              <input
                id="file-input"
                type="file"
                style={{ cursor: "pointer" }}
                accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                onChange={(e) => {
                  if (
                    !(
                      e.target.files[0].type === "image/png" ||
                      e.target.files[0].type === "image/jpg" ||
                      e.target.files[0].type === "image/jpeg" ||
                      e.target.files[0].type === "image/gif" ||
                      e.target.files[0].type === "application/pdf" ||
                      e.target.files[0].type === "application/msword" ||
                      e.target.files[0].type ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    )
                  ) {
                    alert("Only images,pdf,doc,docx allowded");
                    return false;
                  }
                  if (e.target.files[0].size > 2097152) {
                    alert("Not More than 2 MB is allowded");
                    return false;
                  }

                  swal({
                    title: "Are you sure?",
                    text: `Are you sure to share Image  ${e.target.files[0].name} file !`,
                    icon: "warning",
                    dangerMode: true,
                    buttons: ["cancel", "ok"],
                  }).then((willDelete) => {
                    if (willDelete) {
                      handleSubmit(e.target.files[0], "file");
                    } else {
                    }
                  });
                }}
              />
            </div>
          </div>
        </div>
        <button
          onClick={handleSubmit}
          disabled={loading ? true : false}
          type="button"
          className="button button-primary"
        >
          <SendIcon />
        </button>
      </div>
    </>
  );
};

export default SupportChatInput;
