import React, { useContext, useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import { ReactComponent as SaveDraftIcon } from "../assets/images/save-draft.svg";
import { ReactComponent as SendOfferIcon } from "../assets/images/send-offer.svg";
import { ReactComponent as RejectIcon } from "../assets/images/short-reject.svg";
import { ReactComponent as OfferAcceptIcon } from "../assets/images/offer-accept.svg";
import { ReactComponent as OrderMeetingIcon } from "../assets/images/order-meeting.svg";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Skeleton from "react-loading-skeleton";
import { cloneDeep } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AcceptIcon } from "../assets/images/offer-accept.svg";
import Auth from "../libs/auth";

function SendOffer() {
  const location = useLocation();
  const navigate = useNavigate();
  const [shortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [index, setIndex] = useState();
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [GstAvg, setGstAvg] = useState(0);
  const [TotalData, setTotalData] = useState({
    total: 0,
    freightCharge: 0,
    afterFreight: 0,
    gst: 0,
    totolInvoice: 0,
  });
  const [DisableAccept, setDisableAccept] = useState(false);

  const user = Auth.getCurrentUser();

  useEffect(() => {
    TotalDataFunc();
  }, [products]);

  useEffect(() => {
    TotalDataFunc();
  }, [value]);

  useEffect(() => {
    FreightCharfe();
  }, [TotalData]);
  useEffect(() => {
    afterFreight();
  }, [TotalData]);

  useEffect(() => {
    GstWithAvg();
  }, [TotalData]);

  useEffect(() => {
    FinalInvoic();
  }, [TotalData]);

  function TotalDataFunc() {
    let ttal = 0;

    products?.map((item, index) => {
      if (value[index] === undefined) {
        ttal =
          ttal + Number(item?.quantity) * Number(item?.negotiation?.amount);
      } else if (value[index] <= 0) {
        // toast.error("Not Allowded Numbers");
        ttal =
          ttal + Number(item?.quantity) * Number(item?.negotiation?.amount);
      } else {
        // alert(value[index])
        ttal = ttal + Number(item?.quantity) * Number(value[index]);
      }
    });
    if (value.length >= 1) {
      var res = value.filter((elements) => {
        return elements === "";
      });

      if (value.length === res.length) {
        setDisableAccept(false);
      } else {
        setDisableAccept(true);
      }
    }

    setTotalData((p) => ({ ...p, total: ttal }));
  }

  function FreightCharfe() {
    if (TotalData?.total > 0) {
      let fData =
        (Number(shortOrderDetails?.freight_charge_percentage) *
          Number(TotalData?.total)) /
        100;
      setTotalData((p) => ({ ...p, freightCharge: fData }));
    }
  }

  function afterFreight() {
    let Aftr = TotalData?.total + TotalData?.freightCharge;
    setTotalData((p) => ({ ...p, afterFreight: Aftr }));
  }
  function GstWithAvg() {
    let gst = 0;
    let freight = 0;
    products?.map((item, index) => {
      freight = 0;
      if (value[index] === undefined) {
        freight =
          (Number(item?.quantity) *
            Number(item?.negotiation?.amount) *
            Number(shortOrderDetails?.freight_charge_percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(item?.negotiation?.amount) +
            freight) *
            Number(item?.negotiation?.gst?.percentage)) /
            100;
      } else if (value[index] <= 0) {
        // toast.error("Not Allowded Numbers");
        freight =
          (Number(item?.quantity) *
            Number(item?.negotiation?.amount) *
            Number(shortOrderDetails?.freight_charge_percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(item?.negotiation?.amount) +
            freight) *
            Number(item?.negotiation?.gst?.percentage)) /
            100;
      } else {
        // alert(value[index])
        freight =
          (Number(item?.quantity) *
            Number(value[index]) *
            Number(shortOrderDetails?.freight_charge_percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(value[index]) + freight) *
            Number(item?.negotiation?.gst?.percentage)) /
            100;
        // gst = gst + (Number(item?.quantity) * Number(value[index]));
      }
    });
    let gstW = (TotalData?.total * GstAvg) / 100;
    setTotalData((p) => ({ ...p, gst: gst }));
  }
  function FinalInvoic() {
    let Final = TotalData?.afterFreight + TotalData?.gst;

    setTotalData((p) => ({ ...p, totolInvoice: Final }));
  }

  const getNegotiationDetailSO = async () => {
    setLoading(true);
    await get(
      `/negotiation/details/${location?.state?.negotiationId}`,
      getAuthConfig()
    )
      .then((res) => {
        setLoading(false);
        setShortOrderDetails(res?.data?.data);
        setProducts(res?.data?.data?.items);
        let gstSumm = 0;

        res?.data?.data?.items.map((item, index) => {
          gstSumm = gstSumm + Number(item?.gst?.percentage);
        });
        setGstAvg(
          parseFloat(gstSumm / res?.data?.data?.items.length).toFixed(2)
        );
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response.status === 400 &&
          err?.response?.data?.message === "Short order not found"
        ) {
          localStorage.removeItem("SOID");
        }
      });
  };

  const sendFinalOffer = async (id) => {
    let callApi = false;
    if (value?.length === 0) {
      toast.error("Please Enter Negotitation Price in atleast one field");

      return false;
    } else if (value?.length >= 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] <= 0) {
          toast.error("Please Enter Valid Negotitation Price ");

          return false;
        } else {
          callApi = true;
        }
      }
    }

    if (callApi === true) {
      const formData = new FormData();
      for (let i = 0; i < products.length; i++) {
        const item = products[i];
        const orderItemId = item.id;

        // check if price is greater than 0
        const price = value[i];
        if (price > 0) {
          // append order_item_id and price to formData
          formData.append(`negotiation[${i}][item_id]`, orderItemId);
          formData.append(`negotiation[${i}][amount]`, price);
        }
      }
      formData.append("negotiation_id", id);
      formData.append("type", "final_offer");
      await post("/negotiation/send_offer", formData, getAuthConfig())
        .then((res) => {
          toast.success("Offer send successfully");
          navigate("/negotiation-seller");
        })
        .catch((err) => {});
    }
  };

  const saveDraft = async (id) => {
    let callApi = false;
    if (value?.length === 0) {
      toast.error("Please Enter Negotitation Price in atleast one field");

      return false;
    } else if (value?.length >= 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] <= 0) {
          toast.error("Please Enter Valid Negotitation Price ");

          return false;
        } else {
          callApi = true;
        }
      }
    }

    if (callApi === true) {
      const formData = new FormData();
      for (let i = 0; i < products.length; i++) {
        const item = products[i];
        const orderItemId = item.id;

        const price = value[i];
        if (price > 0) {
          formData.append(`negotiation[${i}][item_id]`, orderItemId);
          formData.append(`negotiation[${i}][amount]`, price);
        }
      }
      formData.append("negotiation_id", id);
      await post("/negotiation/save_draft", formData, getAuthConfig())
        .then((res) => {
          toast.success("Draft saved successfully");

          setTimeout(() => {
            navigate("/negotiation-seller");
          }, 2000);
        })
        .catch((err) => {});
    }
  };

  const handleAcceptOrReject = async (action, id) => {
    const formData = new FormData();
    formData.append("type", action);
    formData.append("negotiation_id", id);
    await post("/negotiation/action", formData, getAuthConfig()).then((res) => {
      if (action === "reject") {
        toast.success("Offer Rejected Successfully ");
        setTimeout(() => {
          navigate("/negotiation-seller");
        }, 1000);
      } else if (action === "accept") {
        getReleaseorder(id);
        toast.success("Offer Accepted Successfully ");
        setTimeout(() => {
          navigate("/transaction");
        }, 1000);
      }
    });
  };

  const getReleaseorder = async (id) => {
    await get(`/negotiation/release-short-order/${id}`, getAuthConfig())
      .then((res) => {
        // localStorage.setItem("SOID", res?.data?.data?.id);
      })
      .catch((err) => {});
  };

  UseEffectOnce(() => {
    getNegotiationDetailSO();
  });

  const handleSellerNegotiationPrice = (e, index) => {
    setValue((prev) => {
      const newDivisions = cloneDeep(prev);
      newDivisions[index] = e.target.value;
      setIndex(index);

      return newDivisions;
    });
  };



  const createThread = async (item) => {
    const formdata = new FormData();
    formdata.append("to_user_id",item);
    await postwithOu("/chat/init_thread", getAuthConfig(), formdata)
      .then((res) => {
        navigate("/chat-room", {
          state: {
            id:
              res?.data?.data?.to_user_id === user?.id
                ? res?.data?.data?.user_id
                : res?.data?.data?.to_user_id,
          },
        });
      })
      .catch((err) => { });
  };

  console.log(value,"hello")

  return (
    <>
      <ToastContainer />

      <Header home disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <div className="order">
            <div className="page-title">
              <h6>Seller Send Final Offer / Seller View</h6>
            </div>
            <div className="order-table">
              {!products?.length && !loading ? (
                <>No Data Found</>
              ) : (
                <>
                  {loading ? (
                    <>
                      <Skeleton height={270} />
                    </>
                  ) : (
                    <table>
                      <thead>
                        <tr>
                          <th>Sr NO</th>
                          <th>Product Category, Product Sub Category</th>
                          <th>Product Differentiation</th>
                          <th>Required Qty</th>
                          <th>GST (%)</th>
                          <th>Seller Offer Price per Qty</th>
                          <th className={shortOrderDetails?.status != "draft" ||
                          shortOrderDetails?.status != "initial_offer" ? "" : "height-light"}>
                            Required Price By Buyer
                          </th>

                          {shortOrderDetails?.status === "draft" ||
                          shortOrderDetails?.status === "initial_offer" ? (
                            <th className="height-light">Final Offer Price</th>
                          ) : (
                            ""
                          )}
                          <th>Total Gross Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products?.map((item, index) => (
                          <>
                            <tr key={item?.id}>
                              <td>{index + 1}</td>
                              <td>{`${item?.product?.category?.name}`}</td>
                              <td>
                                {item?.product?.brand?.name &&
                                  `Brand: ${item?.product?.brand?.name},`}
                                {item?.product?.size &&
                                  `Size: ${item?.product?.size},`}
                                {item?.product?.color?.name &&
                                  `Color: ${item?.product?.color?.name}`}
                              </td>
                              <td>{item?.quantity}</td>
                              <td>{item?.negotiation?.gst?.percentage}</td>
                              <td>₹{item?.initial?.amount}</td>
                              <td className={shortOrderDetails?.status != "draft" ||
                          shortOrderDetails?.status != "initial_offer" ? "" : "height-light"}>
                                ₹{item?.negotiation?.amount}
                              </td>
                              {shortOrderDetails?.status === "draft" ||
                              shortOrderDetails?.status === "initial_offer" ? (
                                <td className="height-light">
                                  <input
                                    placeholder="Enter Value"
                                    type="text"
                                    value={value[index]}
                                    onChange={(e) => {
                                      handleSellerNegotiationPrice(e, index);
                                    }}
                                  />
                                </td>
                              ) : (
                                ""
                              )}
                              <td>
                                ₹
                                {value[index] != undefined && value[index] > 0
                                  ? parseFloat(
                                      value[index] * item?.quantity
                                    ).toFixed(2)
                                  : item?.negotiation?.sub_total ? item?.negotiation?.sub_total : item?.initial?.sub_total }
                              </td>

                            </tr>
                          </>
                        ))}
                        <tr>
                          {shortOrderDetails?.status === "draft" ||
                          shortOrderDetails?.status === "initial_offer" ? (
                            <td colSpan={"6"}></td>
                          ) : (
                            <td colSpan={"5"}></td>
                          )}
                          <td colSpan={"2"} className="height-light">
                            Total
                          </td>
                          <td className="height-light">
                            ₹{parseFloat(TotalData?.total).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          {shortOrderDetails?.status === "draft" ||
                          shortOrderDetails?.status === "initial_offer" ? (
                            <td colSpan={"6"}></td>
                          ) : (
                            <td colSpan={"5"}></td>
                          )}
                          <td colSpan={"2"} className="height-light percent">
                            {shortOrderDetails?.freight_charge_percentage}%
                            Freight
                          </td>
                          <td className="height-light charge">
                            ₹{parseFloat(TotalData?.freightCharge).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          {shortOrderDetails?.status === "draft" ||
                          shortOrderDetails?.status === "initial_offer" ? (
                            <td colSpan={"6"}></td>
                          ) : (
                            <td colSpan={"5"}></td>
                          )}
                          <td colSpan={"2"} className="height-light">
                            Total after freight
                          </td>
                          <td className="height-light totalFreight">
                            {" "}
                            ₹{parseFloat(TotalData?.afterFreight)?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          {shortOrderDetails?.status === "draft" ||
                          shortOrderDetails?.status === "initial_offer" ? (
                            <td colSpan={"6"}></td>
                          ) : (
                            <td colSpan={"5"}></td>
                          )}
                          <td className="height-light" colSpan={"2"}>
                            GST
                          </td>
                          <td className="height-light gst">
                            ₹{TotalData?.gst}
                          </td>
                        </tr>
                        <tr className="footer">
                          <td className="height-light">Profile ID</td>
                          <td className="height-light">
                            {" "}
                            {shortOrderDetails?.user?.id}
                          </td>
                          <td colSpan={"2"} className="height-light">
                            Payment Terms
                          </td>
                          {shortOrderDetails?.status === "draft" ||
                          shortOrderDetails?.status === "initial_offer" ? (
                            <td colSpan={"2"} className="height-light">
                              {shortOrderDetails?.payment_type}
                            </td>
                          ) : (
                            <td colSpan={"1"} className="height-light">
                              {shortOrderDetails?.payment_type}
                            </td>
                          )}

                          <td colSpan={"2"} className="height-light">
                            Total Invoice Value
                          </td>
                          <td className="height-light invoice">
                            {" "}
                            ₹{parseFloat(TotalData?.totolInvoice).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </>
              )}
              {shortOrderDetails?.status === "draft" ||
              shortOrderDetails?.status === "initial_offer" ? (
                <div className="btn-wrap">
                  <button
                    onClick={() => sendFinalOffer(shortOrderDetails?.id)}
                    className="button button-primary button-primary--bordered"
                    disabled={shortOrderDetails.can_edit === 0 ? true : false}
                  >
                    <SendOfferIcon />
                    Send Final Offer
                  </button>
                  <button
                    onClick={() =>
                      swal({
                        title: "Are you sure?",
                        text: "Are you sure you want to accept this offer  ?",
                        icon: "warning",
                        success: true,
                        buttons: ["Cancel", "Ok"],
                      }).then((willDelete) => {
                        if (willDelete) {
                          setLoading(true);

                          handleAcceptOrReject("accept", shortOrderDetails?.id);
                        } else {
                        }
                      })
                    }
                    className="button button-green button-green--bordered"
                    disabled={
                      DisableAccept === true || shortOrderDetails.can_edit === 0
                        ? true
                        : false
                    }
                  >
                    <OfferAcceptIcon />
                    Accept
                  </button>
                  <button
                    onClick={() =>
                      swal({
                        title: "Are you sure?",
                        text: "Are you sure you want to reject this offer  ?",
                        icon: "warning",
                        dangerMode: true,
                        buttons: ["Cancel", "Ok"],
                      }).then((willDelete) => {
                        setLoading(true);
                        if (willDelete) {

                          handleAcceptOrReject("reject", shortOrderDetails?.id);
                      setLoading(false);

                        } else {
                        }
                      })
                    }
                    className="button button-red button-red--bordered btn-reject"
                    disabled={shortOrderDetails.can_edit === 0 ? true : false}
                  >
                    <RejectIcon />
                    Reject
                  </button>
                  {/* <button
                    onClick={() => saveDraft(shortOrderDetails?.id)}
                    className="button button-blue button-blue--bordered"
                    disabled={shortOrderDetails.can_edit === 0 ? true : false}
                  >
                    <SaveDraftIcon />
                    Save Draft
                  </button> */}
               <button className="button button-primary button-primary--bordered"  
                  onClick={() => 
                  {
                    if(user?.id === shortOrderDetails?.to_user_id){
                      createThread(shortOrderDetails?.user_id)
                    }
                    else{
                      createThread(shortOrderDetails?.to_user_id)

                    }
                  }
                    
                    }>
                    <OrderMeetingIcon />
                    Meeting Room
                  </button>
                </div>
              ) : (
                ""
              )}

              {(shortOrderDetails?.status === "final_offer" ||
              shortOrderDetails.can_edit === 0) && shortOrderDetails?.status != "completed" ? (


                <div
                className="note"
                style={{
                  textAlign:"center",
                  alignItems: "center",
                  marginTop: "35px",
                  color: "red",
                }}
              >
                <p>Waiting for buyer's confirmation</p>
                <Link className=" button button-green button-green--bordered btn-release" to={"/transaction"} >Go Back to Negotiation List</Link>
              </div>
                // <div className="btn-wrap" style={{ color: "red" }}>
                //   {/* <button className="button button-green button-green--bordered btn-release"> */}
                //   {/* <AcceptIcon /> */}
                //   Waiting for the Buyer's Confirmation {/* </button> */}
                // </div>
              ) : (
                ""
              )}

                {shortOrderDetails?.status === "completed" ?
                
                <div
                className="note"
                style={{
                  textAlign:"center",
                  alignItems: "center",
                  marginTop: "35px",
                  color: "green",
                }}
              >
                <p>This transaction is completed</p>
                <Link className=" button button-green button-green--bordered btn-release" to={"/transaction"} >Go Back to Transaction List</Link>
              </div> : ""
              
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendOffer;
