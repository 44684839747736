import React, { useEffect, useState } from "react";
import { ReactComponent as MyActivityIcon } from "../../assets/images/my-activity-icon.svg";
import ActivityReactionGif from "../../assets/images/activity-reaction.gif";
import ActivityCommentGif from "../../assets/images/activity-comment.gif";
import { get, getAuthConfig } from "../../libs/http-hydrate";

const MyActivityCard = ({ id }) => {
  const [activityData, setActivityData] = useState({});
  console.log(activityData, "response---");

  useEffect(() => {
    const getActivities = async () => {
      try {
        const response = await get(
          `/user/profile/user_activity/${id}`,
          getAuthConfig()
        );
        setActivityData(response?.data?.data);
      } catch (error) {
        console.log(error, "error-activity");
      }
    };

    getActivities();
  }, []);

  const checkActivityType = (type, name) => {
    let text;
    switch (type) {
      case "reaction":
        text = `Reacted on ${name}`;
        break;
      case "comment":
        text = `Commented on ${name}`;
        break;
      default:
        text = "";
        break;
    }
    return text;
  };

  const checkActivityIcon = (type) => {
    let icon;
    switch (type) {
      case "reaction":
        icon = ActivityReactionGif;

        break;
      case "comment":
        icon = ActivityCommentGif;

        break;

      default:
        break;
    }
    return icon;
  };

  return (
    <div className="card">
      <div className="card-header card-header--large">
        <h6>
          <MyActivityIcon />
          My Activity
        </h6>
      </div>
      <div className="card-body">
        <div className="card-lists">
          {activityData.items && activityData.items.length > 0 ? (
            <>
              {activityData?.items?.map((item) => (
                <>
                  <div className="card-lists-item user-profile">
                    <div
                      className="user-profile-image"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ height: 20, width: 20, marginBottom: 10 }}
                        src={checkActivityIcon(item?.type)}
                        alt="Img"
                        className="profile-pic"
                      />
                    </div>
                    <div className="user-profile-content">
                      <div className="user-profile-name">
                        <h6>
                          {checkActivityType(item?.type, item?.activity?.name)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              <p>No Activity Found....</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyActivityCard;
