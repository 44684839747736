import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { ReactComponent as HeartFilledIcon } from "../assets/images/heart-filled.svg";
import { ReactComponent as MyTransactionIcon } from "../assets/images/my-transaction-icon.svg";
import { ReactComponent as NegotiationIcon } from "../assets/images/negotiation-new.svg";
import { ReactComponent as FormIcon } from "../assets/images/prefilled-forms-new.svg";
import { ReactComponent as MyNetworkIcon } from "../assets/images/my-network-icon.svg";
import { ReactComponent as PendingIcon } from "../assets/images/pending-icon.svg";
import { ReactComponent as ProtfolioIcon } from "../assets/images/protfolio-icon.svg";
import { ReactComponent as MyPostIcon } from "../assets/images/my-post-icon.svg";
import Auth from "../libs/auth";
import Skeleton from "react-loading-skeleton";
import { SwitchProfile } from "../context/switchProfile.context";

function LeftSideBar({ childFunc }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const user = Auth.getCurrentUser();
  const { accountFor, toggleAccount, setBuyerAccount, setSellerAccount } =
    useContext(SwitchProfile);
  UseEffectOnce(() => {
    fetchData();
  });
  useEffect(() => {
    fetchData();
  }, [childFunc]);
  async function fetchData() {
    // setLoading(true);
    // const data = await get("/user/profile/basic_details", getAuthConfig());
    // if (data.status === 200) {
    //   setLoading(false);
    //   setData( data.data );
    // }
    // setLoading(false);
  }
  return (
    <>
      <div className="layout-grid layout-grid--left">
        <div className="card card-profile">
          <div className="card-inner">
            <div className="card-body">
              <div className="card-profile-image">
                {loading ? (
                  <div>
                    <Skeleton width={194} height={67.16} />
                  </div>
                ) : (
                  <div className="profile-bg">
                    <img
                      src={user?.cover_image}
                      className="profile-pic"
                      alt=""
                    />
                  </div>
                )}

                {loading ? (
                  <div className="profile-main">
                    <Skeleton circle width={68.01} height={68.01} />
                  </div>
                ) : (
                  <Link to={`/edit-profile-seller/${user?.slug}`}>
                    <div className="profile-main">
                      <img
                        src={user?.profile_image}
                        className="profile-pic"
                        alt=""
                      />
                    </div>
                  </Link>
                )}
              </div>

              <div className="profile-name">
                {loading ? (
                  <div>
                    <Skeleton width={194} height={68.01} />
                  </div>
                ) : (
                  <>
                    <Link to={`/edit-profile-seller/${user?.slug}`}>
                      <h6>{user?.name}</h6>
                    </Link>
                    <p className="profile-bio">{user?.about}</p>
                  </>
                )}
              </div>
              <div className="profile-links">
                <ul>
                  <li>
                    <Link to={"/transaction"}>
                      <MyTransactionIcon />
                      My Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to={"/settings"} state={{ PreFilled: "true" }}>
                      <FormIcon />
                      Pre Filled Forms
                    </Link>
                  </li>
                  <li>
                    <Link to={"/my-network"}>
                      <MyNetworkIcon />
                      My Network
                    </Link>
                  </li>
                  <li>
                    <Link to={"/received-request"}>
                      <PendingIcon />
                      Pending Requests{" "}
                      <strong>
                        {localStorage.getItem("PENDREQ")
                          ? "(" + localStorage.getItem("PENDREQ") + ")"
                          : ""}
                      </strong>
                    </Link>
                  </li>
                  {accountFor?.buyer === true ? (
                    ""
                  ) : (
                    <li>
                      <Link to={"/product-portfolio-initial"}>
                        <ProtfolioIcon />
                        Product Portfolio
                      </Link>
                    </li>
                  )}

                  {accountFor?.buyer === true ? (
                    <li>
                      <Link to={"/negotiation-buyer"}>
                        <NegotiationIcon />
                        Negotiation
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link to={"/negotiation-seller"}>
                        <NegotiationIcon />
                        Negotiation
                      </Link>
                    </li>
                  )}
                </ul>
                <ul>
                  <li>
                    <Link to={"/my-post"}>
                      <MyPostIcon />
                      My Post
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeftSideBar;
