import React, { useCallback, useEffect, useRef, useState } from "react";
import "../common/scss/pages/settings.scss";
import "../common/scss/pages/support.scss";
import "../common/scss/pages/short-order.scss";
import Header from "../common/header";
import { ReactComponent as ArrowDownIcon } from "../assets/images/arrow-down.svg";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import { ReactComponent as EmojiIcon } from "../assets/images/emoji.svg";
import TeslaIcon from "../assets/images/tesla-icon.jpg";
import Pusher from "pusher-js";
import { ReactComponent as MoreIcon } from "../assets/images/more-icon.svg";
import { ReactComponent as SendIcon } from "../assets/images/send-icon.svg";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, postwithOu } from "../libs/http-hydrate";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import chatGif from "../assets/images/chat.gif";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Auth from "../libs/auth";
import swal from "sweetalert";
import EmojiPicker from "emoji-picker-react";
import { ToastContainer, toast } from "react-toastify";
function ChatRoom() {
  const navigate = useNavigate();
  const [basic_details, setBasicDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);
  const [emojishow, setemojishow] = useState(false);
  const [usersList, setUsersList] = useState({
    has_more: false,
    items: [],
  });
  const [file, setFile] = useState({
    upload: "",
  });
  const [searchChat, setSearchchat] = useState({
    searchmsg: "",
    pageNo: 0,
  });
  const [refreshData, setRefreshData] = useState(false);
  const [chatPage, setChatPage] = useState(0);
  const [userDetail, setUserDetail] = useState({});
  const [userChat, setUserChat] = useState({
    has_more: false,
    items: [],
  });
  const [currentUser, setCurrentUser] = useState(0);
  const [filterUser, setFilteruser] = useState("recent_chat");
  const [searching, setSearching] = useState("");
  const [socketMsg, setSocketMsg] = useState("");
  const [myMessage, setMyMessage] = useState("");
  const [chatID, setChatID] = useState("");
  const [threadPage ,setThreadPage] = useState(1)
  const scrollDivRef = useRef(null);
  const user = Auth.getCurrentUser();

  const location = useLocation();

  UseEffectOnce(() => {
    fetchDataT();
    setCurrentUser(location?.state?.id);
  }, [location?.state?.id]);

  useEffect(() => {
    // Pusher.logToConsole = true;
    const pusher = new Pusher("91a5dd206e17a2b3e982", {
      channelAuthorization: {
        endpoint: "https://api.busimeet.com/broadcasting/auth",
        headers: { Authorization: Auth.getAuthToken() },
      },
      cluster: "ap2",
      forceTLS: true,
    });
    const channel1 = pusher.subscribe(`private-meeting.room.${userDetail.id}`);
    channel1.bind("chat", function (data) {
      setSocketMsg(data.chatMessage);
    });

    return () => {
      pusher.unsubscribe(`private-meeting.room.${userDetail.id}`);
    };
  }, [chatID, currentUser, userDetail.id]);

  const threadList = async () => {
    setLoading(true);

    let url;
    if (location?.state?.id != undefined || location?.state?.id != null) {
      url = `/chat/threads?page=${threadPage}&type=${filterUser}&search=${searching}&thread_id=${location?.state?.id}`;
    } else {
      url = `/chat/threads?page=${threadPage}&type=${filterUser}&search=${searching}`;
    }
    await get(url, getAuthConfig())
      .then((res) => {
        setLoading(false);
        if(threadPage > 1 ){
          setUsersList((p) => ({ ...p, items: usersList?.items?.concat(res?.data?.data?.items) }));
        }
        else{
          setUsersList((p) => ({ ...p, items: res?.data?.data?.items }));
        }
        // if(res?.data?.data?.has_more){
          setUsersList((p) => ({ ...p, has_more: res?.data?.data?.has_more}));

        // }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleUserFilter = (action) => {
    setFilteruser(action);
  };

  const searchUser = (e) => {
    setThreadPage(1)
    setSearching(e.target.value);
  };

  const getUserChat = async () => {
    setChatLoader(true);
    await get(
      `/chat/history/${currentUser}?page=${chatPage + 1}&search`,
      getAuthConfig()
    )
      .then((res) => {
        setChatID(res?.data?.data?.messages?.items[0]?.chat_room_id);
        setUserDetail(res?.data?.data?.thread);
        setUserChat((p) => ({
          ...p,
          has_more: res?.data?.data?.messages?.has_more,
        }));

        if (chatPage > 0) {
          setUserChat((p) => ({
            ...p,
            items: userChat.items.concat(res?.data?.data?.messages?.items),
          }));
        } else {
          setUserChat((p) => ({
            ...p,
            items: res?.data?.data?.messages?.items,
          }));
        }

        setChatLoader(false);
      })
      .catch((err) => {
        setChatLoader(false);
      });
  };

  useEffect(() => {
    if (socketMsg)
      setUserChat((p) => ({
        ...p,
        items: userChat.items.concat(socketMsg),
      }));
  }, [socketMsg]);

  const handleTypeMessage = (e) => {
    setMyMessage(e.target.value);
  };

  const handleSendMessage = async (id, msg, file) => {
    const formData = new FormData();
    formData.append("to_user_id", id);
    if (msg != undefined && msg != null) {
      formData.append("type", "message");
      formData.append("chat", msg);
    } else if (file != null && file != undefined) {
      formData.append("type", "attachment");
      formData.append("attachment", file);
    } else {
      formData.append("type", "message");
      formData.append("chat", myMessage);
    }

    await postwithOu("/chat", getAuthConfig(), formData)
      .then((res) => {
        setChatID(res.data.data.chat_room_id);
        setChatPage(0);
        setRefreshData(true);
        setMyMessage("");
      })
      .then((err) => {});
  };

  useEffect(() => {
    threadList();
  }, [threadPage]);
  useEffect(() => {
    // if (searching != "") {
      threadList();
    // }
  }, [filterUser, searching]);

  const sortedChatItems =
    userChat?.items.length > 0 &&
    userChat?.items?.sort(
      (a, b) => moment(a?.created_at) - moment(b?.created_at)
    );

  const handleDeleteChat = async (id) => {
    await get(`/chat/delete/${id}`, getAuthConfig())
      .then((res) => {
        if (res.status === 200) {
          getUserChat();
          toast.success("Chat history deleted successfully");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (sortedChatItems && chatPage === 0) {
      const scroll =
        scrollDivRef.current.scrollHeight - scrollDivRef.current.clientHeight;
      scrollDivRef.current.scrollTo(0, scroll);
    }
  }, [sortedChatItems]);

  useEffect(() => {
    if (chatPage > 0) {
      if (userChat.has_more === true) {
        getUserChat();
      }
    }

    if (refreshData === true) {
      getUserChat();
    }
  }, [chatPage]);

  useEffect(() => {
    if (currentUser != 0) {
      getUserChat();
    }
    //   localStorage.setItem("CurrentChatUser", currentUser);
    // } else if (localStorage.getItem("CurrentChatUser")) {
    //   setCurrentUser(localStorage.getItem("CurrentChatUser"));
    // }
  }, [currentUser]);

  async function fetchDataT() {
    const data = await get(
      "/user/setting/get_prefilled_details",
      getAuthConfig()
    )
      .then((res) => {
        if (res.status === 200) {
          setBasicDetails(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          Auth.logout();
          navigate("/login");
        }
      });
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleSendMessage(
          userDetail?.user?.id !== user?.id
            ? userDetail?.user?.id
            : userDetail?.to_user?.id
        );
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSendMessage, userDetail, user]);

  async function searchInChat() {
    setChatLoader(true);
    await get(
      `/chat/history/${currentUser}?page=${searchChat?.pageNo + 1}&search=${
        searchChat?.searchmsg
      }`,
      getAuthConfig()
    )
      .then((res) => {
        setChatID(res?.data?.data?.messages?.items[0]?.chat_room_id);
        setUserDetail(res?.data?.data?.thread);
        setUserChat((p) => ({
          ...p,
          has_more: res?.data?.data?.messages?.has_more,
        }));

        if (searchChat > 0) {
          setUserChat((p) => ({
            ...p,
            items: userChat.items.concat(res?.data?.data?.messages?.items),
          }));
        } else {
          setUserChat((p) => ({
            ...p,
            items: res?.data?.data?.messages?.items,
          }));
        }

        setChatLoader(false);
      })
      .catch((err) => {
        setChatLoader(false);
      });
  }
  async function clearUnreadCount(index) {
    let arr = [];
    arr = usersList?.items;

    arr[index].unread_messages = 0;

    setUsersList((p) => ({ ...p, items: arr }));
  }

  const observer = useRef();
  const lastBookElementRef = useCallback(
      (node) => {
          // if (loading) return;
          if (observer.current) observer.current.disconnect();
          observer.current = new IntersectionObserver((entries) => {
              if (entries[0].isIntersecting && usersList?.has_more) {
                  setThreadPage((prevPageNumber) => prevPageNumber + 1);
              }
          });
          if (node) observer.current.observe(node);
      },
      [usersList?.has_more]
  );

  console.log(usersList,"ekje2j3e2323")

  return (
    <>
      <ToastContainer />
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid support-page chat-room">
          <div className="page-title">
            <h6>Meeting Room</h6>
          </div>
          <div className="layout-grid-box-column2 ">
            <div className="layout-grid layout-grid--left">
              <div className="card sidebar-nav support-sidebar chat-room-sidebar">
                <div className="sidebar-title">
                  <div className="ticket-head">
                    <div className="ticket-head--top">
                      <div className="tabs tabs--solid">
                        <ul>
                          <li
                            className={`${
                              filterUser === "recent_chat" ? "active" : ""
                            }`}
                            onClick={() => handleUserFilter("recent_chat")}
                          >
                            <Link to="">Recent Chat</Link>
                          </li>
                          <li
                            onClick={() => handleUserFilter("online")}
                            className={`${
                              filterUser === "online" ? "active" : ""
                            }`}
                          >
                            <Link to="">Online User</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <a
                    className="collapse-button mobile-view collapsed"
                    data-toggle="collapse"
                    href="#collapseSidebar"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseSearch"
                  >
                    <ArrowDownIcon />
                  </a>
                </div>
                <div
                  className="tickets-list collapse desktop-view"
                  id="collapseSidebar"
                >
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Search"
                      value={searching}
                      onChange={searchUser}
                      onBlur={(e) => {
                        e.preventDefault();
                        clearInterval();
                      }}
                    />
                    <button className="search-button">
                      <SearchIcon />
                    </button>
                  </div>
                  {!usersList?.items?.length && !loading ? (
                    <><div className="no-record">No Threads Found</div></>
                  ) : (
                    <>
                      {/* {loading ? (
                        <Skeleton
                          count={5}
                          height={60}
                          style={{
                            marginTop: "5px",
                            marginBottom: "10px",
                            borderRadius: "5px",
                          }}
                        />
                      ) : ( */}
                        <ul style={{  height: "55vh",
                        "overflow-x": "hidden",
                        "overflow-y": "scroll"}}>
                          {usersList?.items?.map((item, index) => (
                            <li
                              ref={lastBookElementRef}
                              style={{ cursor: "pointer" }}
                              className={
                                currentUser === item?.user?.id ||
                                currentUser === item?.to_user?.id
                                  ? "sidebar-nav--item bg-warning"
                                  : "sidebar-nav--item"
                              }
                              key={index}
                              onClick={(e) => {
                                e.preventDefault();

                                setCurrentUser(
                                  item?.user?.id !== user?.id
                                    ? item?.user?.id
                                    : item.to_user?.id
                                );
                                setChatPage(0);
                                setSearchchat((p) => ({ ...p, pageNo: 0 }));
                                if (item?.unread_messages != 0) {
                                  clearUnreadCount(index);
                                }
                              }}
                            >
                              <div className="user-profile">
                                <div className="user-profile-image">
                                  {/* {user?.id === item?.to_user?.id ? () : ()} */}
                                  <img
                                    src={
                                      user.id !== item.user.id
                                        ? item?.user?.profile_image
                                        : item?.to_user?.profile_image
                                    }
                                    className="profile-pic"
                                    alt="profile-img"
                                  />
                                </div>
                                <div className="user-profile-content">
                                  <div className="user-profile-name">
                                    <h6>
                                      {user?.id != item?.user?.id
                                        ? item?.user?.name
                                        : item?.to_user?.name}
                                    </h6>
                                    <p>
                                      {user?.id != item?.user?.id ? (
                                        item?.user?.company_details?.logo ? (
                                          <img
                                            alt=""
                                            src={
                                              item?.user?.company_details?.logo
                                            }
                                          />
                                        ) : (
                                          ""
                                        )
                                      ) : item?.to_user?.company_details
                                          ?.logo ? (
                                        <img
                                          alt=""
                                          src={
                                            item?.to_user?.company_details?.logo
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}

                                      {user?.id != item?.user?.id
                                        ? item?.user?.company_details?.name
                                          ? item?.user?.company_details?.name
                                              .length > 10
                                            ? item?.user?.company_details?.name.substring(
                                                0,
                                                9
                                              ) + "..."
                                            : item?.user?.company_details?.name
                                          : ""
                                        : item?.to_user?.company_details?.name
                                        ? item?.to_user?.company_details?.name
                                            .length > 10
                                          ? item?.to_user?.company_details?.name.substring(
                                              0,
                                              9
                                            ) + "..."
                                          : item?.to_user?.company_details?.name
                                        : ""}
                                    </p>
                                  </div>

                                  {item?.to_user?.id != user?.id ? (
                                    item?.to_user?.is_online === 1 ? (
                                      <button
                                        className={`button button-primary button-${
                                          item?.to_user?.is_online === 1
                                            ? "online"
                                            : "offline"
                                        }`}
                                      ></button>
                                    ) : (
                                      <button
                                        className={`button button-primary button-${
                                          item?.to_user?.is_online === 1
                                            ? "online"
                                            : "offline"
                                        }`}
                                      ></button>
                                    )
                                  ) : item?.user?.id != user?.id ? (
                                    item?.user?.is_online === 1 ? (
                                      <button
                                        className={`button button-primary button-${
                                          item?.user?.is_online === 1
                                            ? "online"
                                            : "offline"
                                        }`}
                                      ></button>
                                    ) : (
                                      <button
                                        className={`button button-primary button-${
                                          item?.user?.is_online === 1
                                            ? "online"
                                            : "offline"
                                        }`}
                                      ></button>
                                    )
                                  ) : (
                                    ""
                                  )}

                                  {item?.unread_messages > 0 ? (
                                    <p
                                      className="p-2"
                                      style={{
                                        "background-color": "#ffbe26",
                                        top: "1px",
                                        "border-radius": "25px",
                                      }}
                                    >
                                      {item?.unread_messages}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      {/* )} */}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="layout-grid layout-grid--main">
              <div className="card">
                {/* {sortedChatItems || currentUser != 0 ? ( */}
                {currentUser != 0 && currentUser != undefined ? (
                  <>
                    <div className="card-body p-0">
                      <div className="chat-header">
                        <div className="user-profile">
                          <div className="user-profile-image">
                            {chatLoader ? (
                              <>
                                <Skeleton circle height={50} width={50} />
                              </>
                            ) : (
                              <img
                                src={
                                  userDetail?.user?.id !== user?.id
                                    ? userDetail?.user?.profile_image
                                    : userDetail?.to_user?.profile_image
                                }
                                className="profile-pic"
                                alt="profileImg"
                              />
                            )}
                            {userDetail?.user?.id !== user?.id && (
                              <span
                                className={`status ${
                                  userDetail.is_online === 0 ? "online" : ""
                                }`}
                              ></span>
                            )}
                            {userDetail?.to_user?.id !== user?.id && (
                              <span
                                className={`status ${
                                  userDetail.is_online === 0 ? "online" : ""
                                }`}
                              ></span>
                            )}
                          </div>
                          <div className="user-profile-content">
                            <div className="user-profile-name">
                              {chatLoader ? (
                                <Skeleton height={50} width={100} />
                              ) : (
                                <>
                                  <h6>
                                    {userDetail?.user?.id !== user?.id
                                      ? userDetail?.user?.name
                                      : userDetail?.to_user?.name}
                                    {/* <span className="followers">10k Follower</span> */}
                                  </h6>
                                  <p>
                                    {user?.id != userDetail?.user?.id ? (
                                      userDetail?.user?.company_details
                                        ?.logo ? (
                                        <img
                                          alt=""
                                          src={
                                            userDetail?.user?.company_details
                                              ?.logo
                                          }
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : userDetail?.to_user?.company_details
                                        ?.logo ? (
                                      <img
                                        alt=""
                                        src={
                                          userDetail?.to_user?.company_details
                                            ?.logo
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}

                                    {user?.id != userDetail?.user?.id
                                      ? userDetail?.user?.company_details?.name
                                        ? userDetail?.user?.company_details
                                            ?.name
                                        : ""
                                      : userDetail?.to_user?.company_details
                                          ?.name
                                      ? userDetail?.to_user?.company_details
                                          ?.name
                                      : ""}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-input"
                            placeholder="Search Chat"
                            value={searchChat?.searchmsg}
                            onChange={(e) => {
                              e.preventDefault();
                              setSearchchat((p) => ({
                                ...p,
                                searchmsg: e.target.value,
                              }));

                              searchInChat();
                            }}
                          />
                          <button className="search-button">
                            <SearchIcon />
                          </button>
                        </div>
                        <div className="more-btn">
                          <div className="nav-item dropdown account-dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <MoreIcon />
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <button
                                className="dropdown-item"
                                onClick={() => handleDeleteChat(userDetail?.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="chat-body"
                        style={{
                          height: "36vh",
                          "overflow-x": "hidden",
                          "overflow-y": "scroll",
                        }}
                        ref={scrollDivRef}
                        onScroll={(e) => {
                          e.preventDefault();
                          if (scrollDivRef.current.scrollTop === 0) {
                            let p = chatPage;
                            setChatPage(p + 1);
                          }
                        }}
                      >
                        <div style={{ padding: "1rem" }}>
                          {chatLoader === true ? (
                            <div className="text-center">
                              {" "}
                              Loading Messages.....{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {sortedChatItems &&
                          sortedChatItems.length > 0 &&
                          sortedChatItems?.map((item) => (
                            <>
                              {user.id === item?.user_id && (
                                <>
                                  <div className="sender">
                                    <div className="message">
                                      {item?.type === "attachment" ? (
                                        <img
                                          alt=""
                                          src={item?.value}
                                          height={200}
                                          width={200}
                                        />
                                      ) : (
                                        <p>{item?.value}</p>
                                      )}
                                    </div>
                                    <div className="time">
                                      {moment(item?.created_at).format(
                                        "h:mm A"
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                              {item?.user_id !== user?.id && (
                                <>
                                  <div className="receiver">
                                    <div className="profile-pic">
                                      <img
                                        src={userDetail?.to_user?.profile_image}
                                        alt="profileImg"
                                      />
                                    </div>
                                    <div className="profile">
                                      <div className="message">
                                        {item?.type === "attachment" ? (
                                          <img
                                            alt=""
                                            src={item?.value}
                                            height={200}
                                            width={200}
                                          />
                                        ) : (
                                          <p>{item?.value}</p>
                                        )}
                                      </div>
                                      <div className="time">
                                        {moment(item?.created_at).format(
                                          "h:mm A"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                    <div className="card-footer send-message">
                      <div className="chat-box">
                        <input
                          type="text"
                          className="form-input"
                          placeholder="Type Message"
                          onChange={handleTypeMessage}
                          value={myMessage}
                        />
                        <div className="emoji-btn">
                          <EmojiIcon
                            onClick={(e) => {
                              e.preventDefault();
                              setemojishow(!emojishow);
                            }}
                          />
                          {emojishow === true ? (
                            <EmojiPicker
                              onEmojiClick={(e) => {
                                setMyMessage(myMessage + e.emoji);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>

                        <div
                          className="attach-btn"
                          style={{ cursor: "pointer" }}
                        >
                          <div class="image-upload">
                            <label
                              for="file-input"
                              style={{ cursor: "pointer", margin: "0" }}
                            >
                              <AttachmentIcon />
                            </label>

                            <input
                              id="file-input"
                              type="file"
                              style={{ cursor: "pointer" }}
                              accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                              onChange={(e) => {
                                if (
                                  !(
                                    e.target.files[0].type === "image/png" ||
                                    e.target.files[0].type === "image/jpg" ||
                                    e.target.files[0].type === "image/jpeg" ||
                                    e.target.files[0].type === "image/gif" ||
                                    e.target.files[0].type ===
                                      "application/pdf" ||
                                    e.target.files[0].type ===
                                      "application/msword" ||
                                    e.target.files[0].type ===
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  )
                                ) {
                                  alert("Only images,pdf,doc,docx allowded");
                                  return false;
                                }
                                if (e.target.files[0].size > 2097152) {
                                  alert("Not More than 2 MB is allowded");
                                  return false;
                                }

                                setFile((p) => ({
                                  ...p,
                                  upload: e.target.files[0],
                                }));

                                swal({
                                  title: "Are you sure?",
                                  text: `Are you sure to share Image  ${e.target.files[0].name} file !`,
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["cancel", "ok"],
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    handleSendMessage(
                                      userDetail?.user?.id !== user?.id
                                        ? userDetail?.user?.id
                                        : userDetail?.to_user?.id,
                                      null,
                                      e.target.files[0]
                                    );
                                  } else {
                                  }
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="tags">
                          {basic_details?.company_details &&
                          Object.keys(basic_details?.company_details).length !=
                            0 ? (
                            <span
                              className="tag"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                swal({
                                  title: "Are you sure?",
                                  text: "Are you sure to share Your Company Details!",
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["cancel", "ok"],
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    let company =
                                      "Company Name : " +
                                      basic_details?.company_details?.name +
                                      " , " +
                                      "Gst Number : " +
                                      basic_details?.company_details
                                        ?.gst_number +
                                      " , " +
                                      " PAN Number : " +
                                      basic_details?.company_details
                                        ?.pan_number +
                                      " , " +
                                      " Shipping Address : " +
                                      basic_details?.company_details
                                        ?.shipping_address +
                                      " , " +
                                      " Email : " +
                                      basic_details?.company_details?.email +
                                      " ,  Mobile Number : " +
                                      basic_details?.company_details
                                        ?.mobile_number;

                                    handleSendMessage(
                                      userDetail?.user?.id !== user?.id
                                        ? userDetail?.user?.id
                                        : userDetail?.to_user?.id,
                                      company
                                    );
                                  } else {
                                  }
                                });
                              }}
                            >
                              Company Details
                            </span>
                          ) : (
                            ""
                          )}
                          {basic_details?.credit_policy &&
                          Object.keys(basic_details?.credit_policy).length !=
                            0 ? (
                            <span
                              className="tag"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                swal({
                                  title: "Are you sure?",
                                  text: "Are you sure to share your credit policy details !",
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["cancel", "ok"],
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    let credit =
                                      "Nos of Advance Transactions for Credit : " +
                                      basic_details?.credit_policy
                                        ?.advance_transaction +
                                      " , " +
                                      "Credit Period (Days) : " +
                                      basic_details?.credit_policy
                                        ?.credit_period +
                                      " , " +
                                      " Interest Per Week After Due Date : " +
                                      basic_details?.credit_policy
                                        ?.delay_interest +
                                      " , " +
                                      " Credit Period Starts from : " +
                                      basic_details?.credit_policy
                                        ?.credit_period_start +
                                      " , " +
                                      " Interest Period  : " +
                                      basic_details?.credit_policy
                                        ?.interest_period +
                                      " , Other Terms : " +
                                      basic_details?.credit_policy?.other_terms;

                                    handleSendMessage(
                                      userDetail?.user?.id !== user?.id
                                        ? userDetail?.user?.id
                                        : userDetail?.to_user?.id,
                                      credit
                                    );
                                  } else {
                                  }
                                });
                              }}
                            >
                              Credit Policy
                            </span>
                          ) : (
                            ""
                          )}
                          {basic_details?.payment_details &&
                          Object.keys(basic_details?.payment_details).length !=
                            0 ? (
                            <span
                              className="tag"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                swal({
                                  title: "Are you sure?",
                                  text: "Are you sure to share your bank details !",
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["cancel", "ok"],
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    let payment =
                                      "  Bank Name  : " +
                                      basic_details?.payment_details
                                        ?.bank_name +
                                      " , " +
                                      "Bank IFSC Code : " +
                                      basic_details?.payment_details
                                        ?.bank_account_ifsc_code +
                                      " , " +
                                      " Bank Account Number : " +
                                      basic_details?.payment_details
                                        ?.bank_account_number +
                                      " , " +
                                      " Bank Branch : " +
                                      basic_details?.payment_details
                                        ?.bank_branch +
                                      " , " +
                                      " Concern Person Email : " +
                                      basic_details?.payment_details?.email +
                                      " ,  Concern Person Contact Details : " +
                                      basic_details?.payment_details
                                        ?.mobile_number;

                                    handleSendMessage(
                                      userDetail?.user?.id !== user?.id
                                        ? userDetail?.user?.id
                                        : userDetail?.to_user?.id,
                                      payment
                                    );
                                  } else {
                                  }
                                });
                              }}
                            >
                              {" "}
                              Bank Details
                            </span>
                          ) : (
                            ""
                          )}
                          {basic_details?.contacts &&
                          basic_details?.contacts.length > 0 ? (
                            <span
                              className="tag"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                swal({
                                  title: "Are you sure?",
                                  text: "Are you sure to share your contact details!",
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["cancel", "ok"],
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    let contact = "";

                                    basic_details?.contacts?.map(
                                      (item, index) => {
                                        if (item?.name) {
                                          contact =
                                            contact +
                                            "-----> Name :  " +
                                            item?.name +
                                            " , ";
                                        }
                                        if (item?.mobile_number) {
                                          contact =
                                            contact +
                                            "Contact Number :  " +
                                            item?.mobile_number +
                                            " , ";
                                        }

                                        if (item?.whatsapp_number) {
                                          contact =
                                            contact +
                                            "WhatsApp Number :  " +
                                            item?.whatsapp_number +
                                            " , ";
                                        }

                                        if (item?.landline_number) {
                                          contact =
                                            contact +
                                            "LandLine Number :  " +
                                            item?.mobile_number +
                                            " , ";
                                        }

                                        if (item?.email) {
                                          contact =
                                            contact +
                                            "Email  :  " +
                                            item?.email +
                                            " , ";
                                        }
                                      }
                                    );

                                    handleSendMessage(
                                      userDetail?.user?.id !== user?.id
                                        ? userDetail?.user?.id
                                        : userDetail?.to_user?.id,
                                      contact
                                    );
                                  } else {
                                  }
                                });
                              }}
                            >
                              Contact Info
                            </span>
                          ) : (
                            ""
                          )}
                          {basic_details?.docket_details &&
                          Object.keys(basic_details?.docket_details).length !=
                            0 ? (
                            <span
                              className="tag"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                swal({
                                  title: "Are you sure?",
                                  text: "Are you sure to share your docket details !",
                                  icon: "warning",
                                  dangerMode: true,
                                  buttons: ["cancel", "ok"],
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    let company =
                                      "Company Name : " +
                                      basic_details?.company_details?.name +
                                      " , " +
                                      "Gst Number : " +
                                      basic_details?.company_details
                                        ?.gst_number +
                                      " , " +
                                      " PAN Number : " +
                                      basic_details?.company_details
                                        ?.pan_number +
                                      " , " +
                                      " Shipping Address : " +
                                      basic_details?.company_details
                                        ?.shipping_address +
                                      " , " +
                                      " Email : " +
                                      basic_details?.company_details?.email +
                                      " ,  Mobile Number : " +
                                      basic_details?.company_details
                                        ?.mobile_number;

                                    handleSendMessage(
                                      userDetail?.user?.id !== user?.id
                                        ? userDetail?.user?.id
                                        : userDetail?.to_user?.id,
                                      company
                                    );
                                  } else {
                                  }
                                });
                              }}
                            >
                              Docket Details
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <button
                        className="button button-primary"
                        onClick={() =>
                          handleSendMessage(
                            userDetail?.user?.id !== user?.id
                              ? userDetail?.user?.id
                              : userDetail?.to_user?.id
                          )
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSendMessage(
                              userDetail?.user?.id !== user?.id
                                ? userDetail?.user?.id
                                : userDetail?.to_user?.id
                            );
                          }
                        }}
                      >
                        <SendIcon />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card-body p-0">
                      <div className="chat-body text-center">
                        <img src={chatGif} height={400} width={600} />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatRoom;
