import React, { useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
import EBill from "../assets/images/e-bil.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import EWayBillModal from "./EWayBillModal";

function ProductEBill() {
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSO, setLoadingSO] = useState(false);
  const [shortOrderDetails, setShortOrderDetails] = useState();
  const [userData, setUserData] = useState();

  console.log(userData?.data, "initialAccount");
  const parentToChild = (slug) => {
    setUserData(slug);
  };

  let currentStep;

  if (!localStorage.getItem("paymentType")) {
    localStorage.setItem("paymentType", shortOrderDetails?.payment_type);
  }

  const payment = localStorage.getItem("paymentType");

  const location = useLocation();
  const navigate = useNavigate();

  currentStep = payment === "advance" ? "7" : "5";

  const getProductCartSO = async () => {
    if (location?.state?.id) {
      setLoadingSO(true);

      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoadingSO(false);
          setShortOrderDetails(res?.data?.data);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
          // setProducts(res?.data?.data?.items);
        })
        .catch((err) => {
          setLoadingSO(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  useEffect(() => {
    getProductCartSO();
  }, [location?.state?.id]);

  async function AddEwayBills() {
    if (
      shortOrderDetails?.grand_total > 50000 &&
      image === undefined &&
      userData?.data?.is_seller === 1
    )
      return;
    if (
      userData?.data?.is_seller === 0 ||
      (shortOrderDetails?.step?.current_step >= "5" &&
        shortOrderDetails?.payment_type === "credit") ||
      (shortOrderDetails?.step?.current_step >= "7" &&
        shortOrderDetails?.payment_type === "advance")
    )
      return;
    const formData = new FormData();
    formData.append("order_id", shortOrderDetails?.id);
    formData.append("generate_e_way_bill", 0);
    if (image?.name) {
      formData.append("e_way_bill", image);
    }
    try {
      setLoading(true);
      await post("/short_order/update/e-way-bill", formData, getAuthConfig());
      setLoading(false);

      toast.success("E-way bill created successfully");
      setTimeout(() => {
        navigate("/product-material-dispatch", {
          state: { id: shortOrderDetails?.id },
        });
      }, 2000);

      // navigate("/product-e-bill", {
      //   state: { id: shortOrderDetails?.id },
      // });
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message);
    }
  }

  async function updateData() {
    getProductCartSO();
  }

  return (
    <>
      <ToastContainer />
      <Header home disableSwitch userType={parentToChild} />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb />
          <div className="steps">
            <ShortOrderStepper
              currentStep={Number(currentStep)}
              isCompleted={
                userData?.data?.is_seller === 1
                  ? shortOrderDetails?.step?.current_step
                  : currentStep
              }
            />
          </div>

          <div className="order product-order bank-detail-page">
            <div className="page-title">
              <h6>
                E-way Bill receipt (Seller){" "}
                {`${
                  shortOrderDetails?.grand_total < 50000 ? "- Optional" : ""
                }`}
              </h6>
            </div>
            {userData?.data?.is_seller === 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                }}
              >
                <p>
                  <b>Note:</b> If Invoice value is greater than <b>50000 INR</b>
                  , E-Way Bill is Required.
                </p>
              </div>
            )}
            <div
              className={`company-detail bank-detail ${
                (userData?.data?.is_seller === 0 ||
                  (Number(shortOrderDetails?.step?.current_step) >= 5 &&
                    shortOrderDetails?.payment_type === "credit") ||
                  (Number(shortOrderDetails?.step?.current_step) >= 7 &&
                    shortOrderDetails?.payment_type === "advance")) &&
                shortOrderDetails?.links?.e_way_bill === null &&
                "blurred-card"
              }`}
            >
              {(userData?.data?.is_seller === 0 ||
                (Number(shortOrderDetails?.step?.current_step) >= 5 &&
                  shortOrderDetails?.payment_type === "credit") ||
                (Number(shortOrderDetails?.step?.current_step) >= 7 &&
                  shortOrderDetails?.payment_type === "advance")) &&
                shortOrderDetails?.links?.e_way_bill === null && (
                  <div className="overlay"></div>
                )}
              {shortOrderDetails?.links?.e_way_bill ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={shortOrderDetails?.links?.e_way_bill} alt="" />
                </div>
              ) : (
                <div className="row">
                  <div className="form-field col-sm">
                    <label className="form-label">Attach File</label>
                    <div className="file-upload">
                      <input
                        type="file"
                        name="payment_proof"
                        id="upload_attachement"
                        disabled={
                          (shortOrderDetails?.step?.current_step === "5" &&
                            shortOrderDetails?.payment_type === "credit") ||
                          (shortOrderDetails?.step?.current_step === "7" &&
                            shortOrderDetails?.payment_type === "advance")
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (
                            !(
                              e.target.files[0].type === "image/png" ||
                              e.target.files[0].type === "image/jpg" ||
                              e.target.files[0].type === "image/jpeg" ||
                              e.target.files[0].type === "image/gif" ||
                              e.target.files[0].type === "application/pdf" ||
                              e.target.files[0].type === "application/msword" ||
                              e.target.files[0].type ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            )
                          ) {
                            alert("Only images,pdf,doc,docx allowed");
                            return false;
                          }
                          if (e.target.files[0].size > 2097152) {
                            alert("Not More than 2 MB is allowed");
                            return false;
                          }

                          setImage(e.target.files[0]);
                        }}
                      />
                      <label htmlFor="upload_attachement">
                        {image?.name
                          ? image?.name
                          : "  Please choose your attachment file"}

                        <span className="icon">
                          <AttachmentIcon />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!loading && !loadingSO && (
              <div className="btn-wrap">
                <Link
                  state={{
                    id: shortOrderDetails?.id,
                    route: location?.state?.route,
                  }}
                  to={`${
                    payment === "advance"
                      ? "/product-payment"
                      : "/product-purchase-invoice"
                  }`}
                  className="button button-blue"
                >
                  Previous
                </Link>
                {userData?.data?.is_seller === 1 &&
                ((Number(shortOrderDetails?.step?.current_step) < 5 &&
                  shortOrderDetails?.payment_type === "credit") ||
                  (Number(shortOrderDetails?.step?.current_step) < 7 &&
                    shortOrderDetails?.payment_type === "advance")) ? (
                  <button
                    className="button button-primary download-btn"
                    disabled={image?.name ? true : false}
                    data-toggle="modal"
                    data-target="#e-waybillModal"
                  >
                    Generate E-Way Bill
                  </button>
                ) : (
                  ""
                )}

                {!loading && shortOrderDetails?.links?.e_way_bill !== null && (
                  <a
                    href={shortOrderDetails?.links?.e_way_bill}
                    download="E-Way Bill"
                    className="button button-primary download-btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View & Download E-Way Bill
                  </a>
                )}

                <button
                  state={{ id: shortOrderDetails?.id }}
                  disabled={
                    loading ||
                    (image === undefined &&
                      shortOrderDetails?.grand_total > 50000 &&
                      // userData?.data?.is_seller === 1 &&
                      ((Number(shortOrderDetails?.step?.current_step) < 5 &&
                        shortOrderDetails?.payment_type === "credit") ||
                        (Number(shortOrderDetails?.step?.current_step) < 7 &&
                          shortOrderDetails?.payment_type === "advance")))
                      ? true
                      : false
                  }
                  to={`${
                    userData?.data?.is_seller === 0 ||
                    (Number(shortOrderDetails?.step?.current_step) >= 5 &&
                      shortOrderDetails?.payment_type === "credit") ||
                    (Number(shortOrderDetails?.step?.current_step) >= 7 &&
                      shortOrderDetails?.payment_type === "advance")
                      ? "/product-material-dispatch"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      (Number(shortOrderDetails?.step?.current_step) >= 5 &&
                        shortOrderDetails?.payment_type === "credit") ||
                      (Number(shortOrderDetails?.step?.current_step) >= 7 &&
                        shortOrderDetails?.payment_type === "advance")
                    ) {
                      navigate("/product-material-dispatch", {
                        state: {
                          id: shortOrderDetails?.id,
                          route: location?.state?.route,
                        },
                      });
                    } else {
                      AddEwayBills();
                    }
                  }}
                  className="button button-primary"
                >
                  {userData?.data?.is_seller === 1 && image?.name
                    ? "Upload & Next"
                    : "Next"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <EWayBillModal
        updateData={updateData}
        id={shortOrderDetails?.id}
        vehicleNum={shortOrderDetails?.docket_detail?.vehicle_number}
      />
    </>
  );
}

export default ProductEBill;
