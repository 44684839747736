import { useContext, useState, useEffect } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import { ReactComponent as CheckMark } from "../assets/images/check-mark.svg";
import { ReactComponent as CheckMarkFill } from "../assets/images/checkFill.svg";
import ProfilePic from "../assets/images/profile.png";
import { getAuthConfig, postwithOu, get, post } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";

function PriceHideModal(props) {
  const [RUser, setRUser] = useState([]);
  const [users, setUsers] = useState(
    {
      items:[]
    }
    );  const [loading, setLoading] = useState(false);
  const [Filter,setFilter] = useState({
    text:""
  })
  UseEffectOnce(() =>{


    hideUserRequestList()

  },[])
  useEffect(() =>{
    if(Filter?.text != ""){
      hideUserRequestList()
    }
},[Filter?.text])
  const hideUserRequestList = async () => {
    setLoading(true);
    if(Filter?.text){
    try {
      const res = await get(
        `/connection/listing?type=for_product&search=${Filter?.text}`,
        getAuthConfig()
      );
      setLoading(false);
      if (res.status === 200) {
          setUsers((p)=> ({...p , items : res.data.data.connections}));
      }
    } catch (error) {
      setLoading(false);
    }
  }
  else{
      try {
      const res = await get(
        `/connection/listing?type=for_product`,
        getAuthConfig()
      );
      setLoading(false);
      if (res.status === 200) {
        setUsers((p)=> ({...p , items : res.data.data.connections}));
      }
    } catch (error) {
      setLoading(false);
    }
  }
    setLoading(false);
  };


  const handleSubmit = async (id,index,val ) => {

        const formData = new FormData();
    formData.append("user_id", id);
    await post("/product/resticate_user", formData, getAuthConfig())
      .then((res) => {
        let     arr = users.items ;
        arr[index].is_blocked  = arr[index].is_blocked === 1 ? 0 : 1;
       setUsers((p)=> ({...p , items : arr}));
      })
      .catch((error) => {
      });
   
  
  };


  // const handleSubmit = async (id) => {
  //   const formData = new FormData();
  //   formData.append("user_id", id);
  //   await post("/product/resticate_user", formData, getAuthConfig())
  //     .then((res) => {
  //     })
  //     .catch((error) => {
  //     });
  // };


  return (
    <>
      <div
        className="modal fade price-hide-modal"
        id="PriceHideModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="PriceHideModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Price Hide From
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) =>{
                  e.preventDefault()
                  props?.UpdateTrue()
                }}  

              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="price-hide-form">
                <form>
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Search through Profile ID or Name"
                      value={Filter?.text}
                      onChange={(e) =>{
                        setFilter((p) =>({...p,text:e.target.value}))
                      }}
                    />
                    <button className="search-button" onClick={(e) =>{
                      e.preventDefault()
                    }}>
                      <SearchIcon />
                    </button>
                  </div>
                </form>
                <div className="user-list">
                  {loading ? (
                    <>Loading</>
                  ) : (
                    <>
   { users?.items?.length > 0 && users?.items?.map((user,index) => {                        return (
                          <div className="user-item" key={user.id}>
                            <input
                              type="checkbox"
                              name="user_list"
                              checked={user?.is_blocked === 1 ? true : ""}
                              id={user.id}
                              onChange={() => {
                                console.log(user.id , index , user?.is_blocked)
                                handleSubmit(user.id , index , user?.is_blocked);
                              }}
                              // value={checked}
                            />
                            <label htmlFor={user.id}>
                              <div className="profile-image">
                                <img
                                  src={user.profile_image}
                                  alt="profileImg"
                                />
                              </div>
                              <div className="profile-content">
                                <h6>{user.name}</h6>
                                <p>(Profile ID: {user.id})</p>
                              </div>
                              {
                                user?.is_blocked === 1 ? 
                                <span  >
                                  <CheckMarkFill />
                                </span> : 
                              
                               <span >
                                <CheckMark  />
                              </span>
                              }
                            </label>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
            <button type="button" className="button button-primary"      data-dismiss="modal"
                aria-label="Close" onClick={(e) =>{
                  e.preventDefault()
                  props?.UpdateTrue()
                }}>
                Save & Close
              </button>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceHideModal;
