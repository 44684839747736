import React, { useContext, useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
import { ReactComponent as TickIcon } from "../assets/images/tick-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import { ToastContainer } from "react-toastify";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import { SwitchProfile } from "../context/switchProfile.context";

function ProductCompanyDetail() {
  const { accountFor } = useContext(SwitchProfile);

  const location = useLocation();
  const user = Auth.getCurrentUser();
  const [ShortOrderDetails, setShortOrderDetails] = useState({});
  const [loading, setLoading] = useState(false);
  let currentStep = 2;

  const navigate = useNavigate();
  useEffect(() => {
    getProductCartSO();
  }, [location?.state?.id]);

  const getProductCartSO = async () => {
    setLoading(true);
    if (location?.state?.id) {
      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoading(false);
          setShortOrderDetails(res?.data?.data);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
        })
        .catch((err) => {
          setLoading(false);

          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  useEffect(() => {
    getProductCartSO();
  }, [localStorage.getItem("SOID"), location?.state?.id]);

  console.log(accountFor, "henlo");
  return (
    <>
      <ToastContainer />
      <Header home disableSwitch />
      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb />
          <div className="steps">
            <ShortOrderStepper
              currentStep={currentStep}
              isCompleted={ShortOrderDetails?.step?.current_step}
              orderId={ShortOrderDetails?.id}
            />
          </div>
          <div className="order product-order company-detail-page">
            <div className="company-detail">
              <div className="flex-box">
                <div className="flex-item">
                  <div className="page-title">
                    <h6>Seller's Details</h6>
                  </div>
                  <input
                    type="radio"
                    name="company_detail"
                    id="company_detail"
                  />
                  <label htmlFor="company_detail">
                    <span className="tick-mark">
                      <TickIcon />
                    </span>

                    {accountFor?.buyer === false ? (
                      user?.id === ShortOrderDetails?.to_user?.id ? (
                        <div className="company-detail--filled">
                          <div className="company-profile">
                            <div className="company-profile-image">
                              <img
                                src={
                                  ShortOrderDetails?.to_user?.company_details
                                    ?.logo
                                }
                                className="profile-pic"
                                alt=""
                              />
                            </div>
                            <div className="company-profile-content">
                              <div className="company-profile-name">
                                <h6>
                                  {
                                    ShortOrderDetails?.to_user?.company_details
                                      ?.name
                                  }
                                </h6>
                                <ul>
                                  {ShortOrderDetails?.to_user?.company_details
                                    ?.gst_number != null ? (
                                    <li>
                                      <label>GST</label>:
                                      <span>
                                        {ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                          0,
                                          2
                                        )}
                                        XXXXXXX
                                        {ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                          10,
                                          14
                                        )}
                                        <span className="verified">
                                          <TickIcon />
                                        </span>
                                      </span>
                                    </li>
                                  ) : (
                                    <li>
                                      <label>GST</label>:<span>N/A</span>
                                    </li>
                                  )}

                                  <li>
                                    <label>PAN NO</label>:
                                    <span>
                                      {ShortOrderDetails?.to_user
                                        ?.company_details?.pan_number != null
                                        ? ShortOrderDetails?.to_user
                                            ?.company_details?.pan_number
                                        : "N/A"}

                                      {ShortOrderDetails?.to_user
                                        ?.company_details?.pan_number !=
                                      null ? (
                                        <span className="verified">
                                          <TickIcon />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="company-info">
                            <div className="info-box">
                              <div className="info-item">
                                <label>Shipping Address</label>
                                <span>
                                  {
                                    ShortOrderDetails?.to_user?.company_details
                                      ?.shipping_address
                                  }
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-sm">
                                  <div className="info-item">
                                    <label>STATE</label>
                                    <span>
                                      {ShortOrderDetails?.to_user
                                        ?.company_details?.region?.parent?.name
                                        ? ShortOrderDetails?.to_user
                                            ?.company_details?.region?.parent
                                            ?.name
                                        : "N/A"}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm">
                                  <div className="info-item">
                                    <label>STATE CODE</label>
                                    <span>
                                      {ShortOrderDetails?.to_user
                                        ?.company_details?.gst_number
                                        ? ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                            0,
                                            2
                                          )
                                        : "N/A"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm">
                                  <div className="info-item">
                                    <label>MOBILE NUMBER</label>

                                    {ShortOrderDetails?.to_user
                                      ?.mobile_number != null ? (
                                      <span>
                                        {
                                          ShortOrderDetails?.to_user
                                            ?.mobile_number
                                        }
                                      </span>
                                    ) : (
                                      <span>N/A </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-sm">
                                  <div className="info-item">
                                    <label>EMAIL ID</label>
                                    <span>
                                      {
                                        ShortOrderDetails?.to_user
                                          ?.company_details?.email
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="company-detail--filled">
                          <div className="company-profile">
                            <div className="company-profile-image">
                              <img
                                src={
                                  ShortOrderDetails?.user?.company_details?.logo
                                }
                                className="profile-pic"
                                alt=""
                              />
                            </div>
                            <div className="company-profile-content">
                              <div className="company-profile-name">
                                <h6>
                                  {
                                    ShortOrderDetails?.user?.company_details
                                      ?.name
                                  }
                                </h6>
                                <ul>
                                  {ShortOrderDetails?.user?.company_details
                                    ?.gst_number != null ? (
                                    <li>
                                      <label>GST</label>:
                                      <span>
                                        {ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                          0,
                                          2
                                        )}
                                        XXXXXXX
                                        {ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                          10,
                                          14
                                        )}
                                        <span className="verified">
                                          <TickIcon />
                                        </span>
                                      </span>
                                    </li>
                                  ) : (
                                    <li>
                                      <label>GST</label>:<span>N/A</span>
                                    </li>
                                  )}

                                  <li>
                                    <label>PAN NO</label>:
                                    <span>
                                      {ShortOrderDetails?.user?.company_details
                                        ?.pan_number != null
                                        ? ShortOrderDetails?.user
                                            ?.company_details?.pan_number
                                        : "N/A"}

                                      {ShortOrderDetails?.user?.company_details
                                        ?.pan_number != null ? (
                                        <span className="verified">
                                          <TickIcon />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="company-info">
                            <div className="info-box">
                              <div className="info-item">
                                <label>Shipping Address</label>
                                <span>
                                  {
                                    ShortOrderDetails?.user?.company_details
                                      ?.shipping_address
                                  }
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-sm">
                                  <div className="info-item">
                                    <label>STATE</label>
                                    <span>
                                      {ShortOrderDetails?.user?.company_details
                                        ?.region?.parent?.name
                                        ? ShortOrderDetails?.user
                                            ?.company_details?.region?.parent
                                            ?.name
                                        : "N/A"}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm">
                                  <div className="info-item">
                                    <label>STATE CODE</label>
                                    <span>
                                      {ShortOrderDetails?.user?.company_details
                                        ?.gst_number
                                        ? ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                            0,
                                            2
                                          )
                                        : "N/A"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm">
                                  <div className="info-item">
                                    <label>MOBILE NUMBER</label>

                                    {ShortOrderDetails?.user?.mobile_number !=
                                    null ? (
                                      <span>
                                        {ShortOrderDetails?.user?.mobile_number}
                                      </span>
                                    ) : (
                                      <span>N/A </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-sm">
                                  <div className="info-item">
                                    <label>EMAIL ID</label>
                                    <span>
                                      {
                                        ShortOrderDetails?.user?.company_details
                                          ?.email
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : user?.id === ShortOrderDetails?.to_user?.id ? (
                      <div className="company-detail--filled">
                        <div className="company-profile">
                          <div className="company-profile-image">
                            <img
                              src={
                                ShortOrderDetails?.user?.company_details?.logo
                              }
                              className="profile-pic"
                              alt=""
                            />
                          </div>
                          <div className="company-profile-content">
                            <div className="company-profile-name">
                              <h6>
                                {ShortOrderDetails?.user?.company_details?.name}
                              </h6>
                              <ul>
                                {ShortOrderDetails?.user?.company_details
                                  ?.gst_number != null ? (
                                  <li>
                                    <label>GST</label>:
                                    <span>
                                      {ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                        0,
                                        2
                                      )}
                                      XXXXXXX
                                      {ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                        10,
                                        14
                                      )}
                                      <span className="verified">
                                        <TickIcon />
                                      </span>
                                    </span>
                                  </li>
                                ) : (
                                  <li>
                                    <label>GST</label>:<span>N/A</span>
                                  </li>
                                )}

                                <li>
                                  <label>PAN NO</label>:
                                  <span>
                                    {ShortOrderDetails?.user?.company_details
                                      ?.pan_number != null
                                      ? ShortOrderDetails?.user?.company_details
                                          ?.pan_number
                                      : "N/A"}

                                    {ShortOrderDetails?.user?.company_details
                                      ?.pan_number != null ? (
                                      <span className="verified">
                                        <TickIcon />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="company-info">
                          <div className="info-box">
                            <div className="info-item">
                              <label>Shipping Address</label>
                              <span>
                                {
                                  ShortOrderDetails?.user?.company_details
                                    ?.shipping_address
                                }
                              </span>
                            </div>
                            <div className="row">
                              <div className="col-sm">
                                <div className="info-item">
                                  <label>STATE</label>
                                  <span>
                                    {ShortOrderDetails?.user?.company_details
                                      ?.region?.parent?.name
                                      ? ShortOrderDetails?.user?.company_details
                                          ?.region?.parent?.name
                                      : "N/A"}
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm">
                                <div className="info-item">
                                  <label>STATE CODE</label>
                                  <span>
                                    {ShortOrderDetails?.user?.company_details
                                      ?.gst_number
                                      ? ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                          0,
                                          2
                                        )
                                      : "N/A"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm">
                                <div className="info-item">
                                  <label>MOBILE NUMBER</label>

                                  {ShortOrderDetails?.user?.mobile_number !=
                                  null ? (
                                    <span>
                                      {ShortOrderDetails?.user?.mobile_number}
                                    </span>
                                  ) : (
                                    <span>N/A </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm">
                                <div className="info-item">
                                  <label>EMAIL ID</label>
                                  <span>
                                    {
                                      ShortOrderDetails?.user?.company_details
                                        ?.email
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="company-detail--filled">
                        <div className="company-profile">
                          <div className="company-profile-image">
                            <img
                              src={
                                ShortOrderDetails?.to_user?.company_details
                                  ?.logo
                              }
                              className="profile-pic"
                              alt=""
                            />
                          </div>
                          <div className="company-profile-content">
                            <div className="company-profile-name">
                              <h6>
                                {
                                  ShortOrderDetails?.to_user?.company_details
                                    ?.name
                                }
                              </h6>
                              <ul>
                                {ShortOrderDetails?.to_user?.company_details
                                  ?.gst_number != null ? (
                                  <li>
                                    <label>GST</label>:
                                    <span>
                                      {ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                        0,
                                        2
                                      )}
                                      XXXXXXX
                                      {ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                        10,
                                        14
                                      )}
                                      <span className="verified">
                                        <TickIcon />
                                      </span>
                                    </span>
                                  </li>
                                ) : (
                                  <li>
                                    <label>GST</label>:<span>N/A</span>
                                  </li>
                                )}

                                <li>
                                  <label>PAN NO</label>:
                                  <span>
                                    {ShortOrderDetails?.to_user?.company_details
                                      ?.pan_number != null
                                      ? ShortOrderDetails?.to_user
                                          ?.company_details?.pan_number
                                      : "N/A"}

                                    {ShortOrderDetails?.to_user?.company_details
                                      ?.pan_number != null ? (
                                      <span className="verified">
                                        <TickIcon />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="company-info">
                          <div className="info-box">
                            <div className="info-item">
                              <label>Shipping Address</label>
                              <span>
                                {
                                  ShortOrderDetails?.to_user?.company_details
                                    ?.shipping_address
                                }
                              </span>
                            </div>
                            <div className="row">
                              <div className="col-sm">
                                <div className="info-item">
                                  <label>STATE</label>
                                  <span>
                                    {ShortOrderDetails?.to_user?.company_details
                                      ?.region?.parent?.name
                                      ? ShortOrderDetails?.to_user
                                          ?.company_details?.region?.parent
                                          ?.name
                                      : "N/A"}
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm">
                                <div className="info-item">
                                  <label>STATE CODE</label>
                                  <span>
                                    {ShortOrderDetails?.to_user?.company_details
                                      ?.gst_number
                                      ? ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                          0,
                                          2
                                        )
                                      : "N/A"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm">
                                <div className="info-item">
                                  <label>MOBILE NUMBER</label>

                                  {ShortOrderDetails?.to_user?.mobile_number !=
                                  null ? (
                                    <span>
                                      {
                                        ShortOrderDetails?.to_user
                                          ?.mobile_number
                                      }
                                    </span>
                                  ) : (
                                    <span>N/A </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm">
                                <div className="info-item">
                                  <label>EMAIL ID</label>
                                  <span>
                                    {
                                      ShortOrderDetails?.to_user
                                        ?.company_details?.email
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </label>
                </div>
                <div className="flex-item">
                  <div className="page-title">
                    <h6>Buyer's Details</h6>
                  </div>
                  {accountFor?.buyer === true &&
                  user?.id === ShortOrderDetails?.to_user?.id &&
                  ShortOrderDetails?.to_user?.company_details?.pan_number ===
                    null ? (
                    ShortOrderDetails?.to_user?.id === user?.id ? (
                      <div className="btn-wrap">
                        <Link
                          className="button button-primary"
                          to={"/settings"}
                          state={{
                            gst: "true",
                            PreFilled: "true",
                            redirectid: ShortOrderDetails?.id,
                          }}
                        >
                          Company Details is not available
                        </Link>
                      </div>
                    ) : (
                      <div className="btn-wrap">
                        <div
                          className="button button-primary"
                          style={{ cursor: "default" }}
                        >
                          Please wait until buyer add a company detail
                        </div>
                      </div>
                    )
                  ) : accountFor?.buyer === true &&
                    user?.id === ShortOrderDetails?.user?.id &&
                    ShortOrderDetails?.user?.company_details?.pan_number ===
                      null ? (
                    ShortOrderDetails?.user?.id === user?.id ? (
                      <div className="btn-wrap">
                        <Link
                          className="button button-primary"
                          to={"/settings"}
                          state={{
                            gst: "true",
                            PreFilled: "true",
                            redirectid: ShortOrderDetails?.id,
                          }}
                        >
                          Company Details is not available
                        </Link>
                      </div>
                    ) : (
                      <div className="btn-wrap">
                        <div
                          className="button button-primary"
                          style={{ cursor: "default" }}
                        >
                          Please wait until buyer add a company detail
                        </div>
                      </div>
                    )
                  ) : (
                    <>
                      <input
                        type="radio"
                        name="company_detail"
                        id="company_detail"
                      />
                      <label htmlFor="company_detail">
                        <span className="tick-mark">
                          <TickIcon />
                        </span>
                        {accountFor?.buyer === true ? (
                          user?.id === ShortOrderDetails?.to_user?.id ? (
                            <div className="company-detail--filled">
                              <div className="company-profile">
                                <div className="company-profile-image">
                                  <img
                                    src={
                                      ShortOrderDetails?.to_user
                                        ?.company_details?.logo
                                    }
                                    className="profile-pic"
                                    alt=""
                                  />
                                </div>
                                <div className="company-profile-content">
                                  <div className="company-profile-name">
                                    <h6>
                                      {
                                        ShortOrderDetails?.to_user
                                          ?.company_details?.name
                                      }
                                    </h6>
                                    <ul>
                                      {ShortOrderDetails?.to_user
                                        ?.company_details?.gst_number !=
                                      null ? (
                                        <li>
                                          <label>GST</label>:
                                          <span>
                                            {ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                              0,
                                              2
                                            )}
                                            XXXXXXX
                                            {ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                              10,
                                              14
                                            )}
                                            <span className="verified">
                                              <TickIcon />
                                            </span>
                                          </span>
                                        </li>
                                      ) : (
                                        <li>
                                          <label>GST</label>:<span>N/A</span>
                                        </li>
                                      )}

                                      <li>
                                        <label>PAN NO</label>:
                                        <span>
                                          {ShortOrderDetails?.to_user
                                            ?.company_details?.pan_number !=
                                          null
                                            ? ShortOrderDetails?.to_user
                                                ?.company_details?.pan_number
                                            : "N/A"}

                                          {ShortOrderDetails?.to_user
                                            ?.company_details?.pan_number !=
                                          null ? (
                                            <span className="verified">
                                              <TickIcon />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="company-info">
                                <div className="info-box">
                                  <div className="info-item">
                                    <label>Shipping Address</label>
                                    <span>
                                      {
                                        ShortOrderDetails?.to_user
                                          ?.company_details?.shipping_address
                                      }
                                    </span>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm">
                                      <div className="info-item">
                                        <label>STATE</label>
                                        <span>
                                          {ShortOrderDetails?.to_user
                                            ?.company_details?.region?.parent
                                            ?.name
                                            ? ShortOrderDetails?.to_user
                                                ?.company_details?.region
                                                ?.parent?.name
                                            : "N/A"}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-sm">
                                      <div className="info-item">
                                        <label>STATE CODE</label>
                                        <span>
                                          {ShortOrderDetails?.to_user
                                            ?.company_details?.gst_number
                                            ? ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                                0,
                                                2
                                              )
                                            : "N/A"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm">
                                      <div className="info-item">
                                        <label>MOBILE NUMBER</label>

                                        {ShortOrderDetails?.to_user
                                          ?.mobile_number != null ? (
                                          <span>
                                            {
                                              ShortOrderDetails?.to_user
                                                ?.mobile_number
                                            }
                                          </span>
                                        ) : (
                                          <span>N/A </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-sm">
                                      <div className="info-item">
                                        <label>EMAIL ID</label>
                                        <span>
                                          {
                                            ShortOrderDetails?.to_user
                                              ?.company_details?.email
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="company-detail--filled">
                              <div className="company-profile">
                                <div className="company-profile-image">
                                  <img
                                    src={
                                      ShortOrderDetails?.user?.company_details
                                        ?.logo
                                    }
                                    className="profile-pic"
                                    alt=""
                                  />
                                </div>
                                <div className="company-profile-content">
                                  <div className="company-profile-name">
                                    <h6>
                                      {
                                        ShortOrderDetails?.user?.company_details
                                          ?.name
                                      }
                                    </h6>
                                    <ul>
                                      {ShortOrderDetails?.user?.company_details
                                        ?.gst_number != null ? (
                                        <li>
                                          <label>GST</label>:
                                          <span>
                                            {ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                              0,
                                              2
                                            )}
                                            XXXXXXX
                                            {ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                              10,
                                              14
                                            )}
                                            <span className="verified">
                                              <TickIcon />
                                            </span>
                                          </span>
                                        </li>
                                      ) : (
                                        <li>
                                          <label>GST</label>:<span>N/A</span>
                                        </li>
                                      )}

                                      <li>
                                        <label>PAN NO</label>:
                                        <span>
                                          {ShortOrderDetails?.user
                                            ?.company_details?.pan_number !=
                                          null
                                            ? ShortOrderDetails?.user
                                                ?.company_details?.pan_number
                                            : "N/A"}

                                          {ShortOrderDetails?.user
                                            ?.company_details?.pan_number !=
                                          null ? (
                                            <span className="verified">
                                              <TickIcon />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="company-info">
                                <div className="info-box">
                                  <div className="info-item">
                                    <label>Shipping Address</label>
                                    <span>
                                      {
                                        ShortOrderDetails?.user?.company_details
                                          ?.shipping_address
                                      }
                                    </span>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm">
                                      <div className="info-item">
                                        <label>STATE</label>
                                        <span>
                                          {ShortOrderDetails?.user
                                            ?.company_details?.region?.parent
                                            ?.name
                                            ? ShortOrderDetails?.user
                                                ?.company_details?.region
                                                ?.parent?.name
                                            : "N/A"}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-sm">
                                      <div className="info-item">
                                        <label>STATE CODE</label>
                                        <span>
                                          {ShortOrderDetails?.user
                                            ?.company_details?.gst_number
                                            ? ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                                0,
                                                2
                                              )
                                            : "N/A"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm">
                                      <div className="info-item">
                                        <label>MOBILE NUMBER</label>

                                        {ShortOrderDetails?.user
                                          ?.mobile_number != null ? (
                                          <span>
                                            {
                                              ShortOrderDetails?.user
                                                ?.mobile_number
                                            }
                                          </span>
                                        ) : (
                                          <span>N/A </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-sm">
                                      <div className="info-item">
                                        <label>EMAIL ID</label>
                                        <span>
                                          {
                                            ShortOrderDetails?.user
                                              ?.company_details?.email
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        ) : user?.id === ShortOrderDetails?.to_user?.id ? (
                          <div className="company-detail--filled">
                            <div className="company-profile">
                              <div className="company-profile-image">
                                <img
                                  src={
                                    ShortOrderDetails?.user?.company_details
                                      ?.logo
                                  }
                                  className="profile-pic"
                                  alt=""
                                />
                              </div>
                              <div className="company-profile-content">
                                <div className="company-profile-name">
                                  <h6>
                                    {
                                      ShortOrderDetails?.user?.company_details
                                        ?.name
                                    }
                                  </h6>
                                  <ul>
                                    {ShortOrderDetails?.user?.company_details
                                      ?.gst_number != null ? (
                                      <li>
                                        <label>GST</label>:
                                        <span>
                                          {ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                            0,
                                            2
                                          )}
                                          XXXXXXX
                                          {ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                            10,
                                            14
                                          )}
                                          <span className="verified">
                                            <TickIcon />
                                          </span>
                                        </span>
                                      </li>
                                    ) : (
                                      <li>
                                        <label>GST</label>:<span>N/A</span>
                                      </li>
                                    )}

                                    <li>
                                      <label>PAN NO</label>:
                                      <span>
                                        {ShortOrderDetails?.user
                                          ?.company_details?.pan_number != null
                                          ? ShortOrderDetails?.user
                                              ?.company_details?.pan_number
                                          : "N/A"}

                                        {ShortOrderDetails?.user
                                          ?.company_details?.pan_number !=
                                        null ? (
                                          <span className="verified">
                                            <TickIcon />
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="company-info">
                              <div className="info-box">
                                <div className="info-item">
                                  <label>Shipping Address</label>
                                  <span>
                                    {
                                      ShortOrderDetails?.user?.company_details
                                        ?.shipping_address
                                    }
                                  </span>
                                </div>
                                <div className="row">
                                  <div className="col-sm">
                                    <div className="info-item">
                                      <label>STATE</label>
                                      <span>
                                        {ShortOrderDetails?.user
                                          ?.company_details?.region?.parent
                                          ?.name
                                          ? ShortOrderDetails?.user
                                              ?.company_details?.region?.parent
                                              ?.name
                                          : "N/A"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm">
                                    <div className="info-item">
                                      <label>STATE CODE</label>
                                      <span>
                                        {ShortOrderDetails?.user
                                          ?.company_details?.gst_number
                                          ? ShortOrderDetails?.user?.company_details?.gst_number.substring(
                                              0,
                                              2
                                            )
                                          : "N/A"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm">
                                    <div className="info-item">
                                      <label>MOBILE NUMBER</label>

                                      {ShortOrderDetails?.user?.mobile_number !=
                                      null ? (
                                        <span>
                                          {
                                            ShortOrderDetails?.user
                                              ?.mobile_number
                                          }
                                        </span>
                                      ) : (
                                        <span>N/A </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-sm">
                                    <div className="info-item">
                                      <label>EMAIL ID</label>
                                      <span>
                                        {
                                          ShortOrderDetails?.user
                                            ?.company_details?.email
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="company-detail--filled">
                            <div className="company-profile">
                              <div className="company-profile-image">
                                <img
                                  src={
                                    ShortOrderDetails?.to_user?.company_details
                                      ?.logo
                                  }
                                  className="profile-pic"
                                  alt=""
                                />
                              </div>
                              <div className="company-profile-content">
                                <div className="company-profile-name">
                                  <h6>
                                    {
                                      ShortOrderDetails?.to_user
                                        ?.company_details?.name
                                    }
                                  </h6>
                                  <ul>
                                    {ShortOrderDetails?.to_user?.company_details
                                      ?.gst_number != null ? (
                                      <li>
                                        <label>GST</label>:
                                        <span>
                                          {ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                            0,
                                            2
                                          )}
                                          XXXXXXX
                                          {ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                            10,
                                            14
                                          )}
                                          <span className="verified">
                                            <TickIcon />
                                          </span>
                                        </span>
                                      </li>
                                    ) : (
                                      <li>
                                        <label>GST</label>:<span>N/A</span>
                                      </li>
                                    )}

                                    <li>
                                      <label>PAN NO</label>:
                                      <span>
                                        {ShortOrderDetails?.to_user
                                          ?.company_details?.pan_number != null
                                          ? ShortOrderDetails?.to_user
                                              ?.company_details?.pan_number
                                          : "N/A"}

                                        {ShortOrderDetails?.to_user
                                          ?.company_details?.pan_number !=
                                        null ? (
                                          <span className="verified">
                                            <TickIcon />
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="company-info">
                              <div className="info-box">
                                <div className="info-item">
                                  <label>Shipping Address</label>
                                  <span>
                                    {
                                      ShortOrderDetails?.to_user
                                        ?.company_details?.shipping_address
                                    }
                                  </span>
                                </div>
                                <div className="row">
                                  <div className="col-sm">
                                    <div className="info-item">
                                      <label>STATE</label>
                                      <span>
                                        {ShortOrderDetails?.to_user
                                          ?.company_details?.region?.parent
                                          ?.name
                                          ? ShortOrderDetails?.to_user
                                              ?.company_details?.region?.parent
                                              ?.name
                                          : "N/A"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm">
                                    <div className="info-item">
                                      <label>STATE CODE</label>
                                      <span>
                                        {ShortOrderDetails?.to_user
                                          ?.company_details?.gst_number
                                          ? ShortOrderDetails?.to_user?.company_details?.gst_number.substring(
                                              0,
                                              2
                                            )
                                          : "N/A"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm">
                                    <div className="info-item">
                                      <label>MOBILE NUMBER</label>

                                      {ShortOrderDetails?.to_user
                                        ?.mobile_number != null ? (
                                        <span>
                                          {
                                            ShortOrderDetails?.to_user
                                              ?.mobile_number
                                          }
                                        </span>
                                      ) : (
                                        <span>N/A </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-sm">
                                    <div className="info-item">
                                      <label>EMAIL ID</label>
                                      <span>
                                        {
                                          ShortOrderDetails?.to_user
                                            ?.company_details?.email
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </label>
                    </>
                  )}
                </div>
              </div>
            </div>
            {!loading && (
              <>
                {accountFor?.buyer === true ? (
                  user?.id === ShortOrderDetails?.user?.id &&
                  ShortOrderDetails?.user?.company_details?.pan_number ==
                    null ? (
                    <div className="btn-wrap">
                      <Link
                        className="button button-primary"
                        to={"/settings"}
                        state={{
                          gst: "true",
                          PreFilled: "true",
                          redirectid: ShortOrderDetails?.id,
                        }}
                      >
                        Please Add Company Details
                      </Link>
                    </div>
                  ) : user?.id === ShortOrderDetails?.to_user?.id &&
                    ShortOrderDetails?.to_user?.company_details?.pan_number ==
                      null ? (
                    <div className="btn-wrap">
                      <Link
                        className="button button-primary"
                        to={"/settings"}
                        state={{
                          gst: "true",
                          PreFilled: "true",
                          redirectid: ShortOrderDetails?.id,
                        }}
                      >
                        Please Add Company Details
                      </Link>
                    </div>
                  ) : (
                    <div className="btn-wrap">
                      <Link
                        to={"/product-short-order"}
                        state={{
                          id: ShortOrderDetails?.id,
                          route: location?.state?.route,
                        }}
                        className="button button-blue"
                      >
                        Previous
                      </Link>
                      <Link
                        to={"/product-purchase-order"}
                        state={{
                          id: ShortOrderDetails?.id,
                          route: location?.state?.route,
                        }}
                        className="button button-primary"
                      >
                        Next
                      </Link>
                    </div>
                  )
                ) : ShortOrderDetails?.to_user?.company_details?.pan_number ==
                    null ||
                  ShortOrderDetails?.user?.company_details?.pan_number ==
                    null ? (
                  <div className="btn-wrap">
                    <span className="text-danger">
                      Please wait until buyer add a company detail
                    </span>
                  </div>
                ) : (
                  <div className="btn-wrap">
                    <Link
                      to={"/product-short-order"}
                      state={{
                        id: ShortOrderDetails?.id,
                        route: location?.state?.route,
                      }}
                      className="button button-blue"
                    >
                      Previous
                    </Link>
                    <Link
                      to={"/product-purchase-order"}
                      state={{
                        id: ShortOrderDetails?.id,
                        route: location?.state?.route,
                      }}
                      className="button button-primary"
                    >
                      Next
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCompanyDetail;
