import React, { useContext, useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
import CompanyLogo from "../assets/images/company-logo.png";
import { ReactComponent as TickIcon } from "../assets/images/tick-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { ReactComponent as DeleteIcon } from "../assets/images/deleteBlck.svg";
import Skeleton from "react-loading-skeleton";
import InvoiceModal from "./InvoiceModal";
import Auth from "../libs/auth";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";

function ProductPurchaseInvoice() {
  const user = Auth?.getCurrentUser();
  const location = useLocation();
  const [ShortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let currentStep = 4;

  useEffect(() => {
    getProductCartSO();
  }, [location?.state?.id]);

  const userData = localStorage.getItem("buyerAccount");

  const getProductCartSO = async () => {
    if (location?.state?.id) {
      setLoading(true);

      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoading(false);
          setShortOrderDetails(res?.data?.data);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
          setProducts(res?.data?.data?.items);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  async function updateData() {
    getProductCartSO();
  }

  if (!localStorage.getItem("paymentType")) {
    localStorage.setItem("paymentType", ShortOrderDetails.payment_type);
  }

  const payment = localStorage.getItem("paymentType");

  return (
    <>
      <Header home disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb />
          <div className="steps">
            <ShortOrderStepper
              currentStep={currentStep}
              isCompleted={ShortOrderDetails?.step?.current_step}
              orderId={ShortOrderDetails?.id}
            />
          </div>
          <div className="order product-order purchase-order-page">
            <div className="page-title">
              <h6>Invoice</h6>
              {Number(ShortOrderDetails?.step?.current_step) >= 4 ? (
                <a
                  href={ShortOrderDetails?.links?.invoice}
                  className="button button-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Invoice
                </a>
              ) : userData === "false" ? (
                <button
                  className="button button-primary"
                  data-toggle="modal"
                  data-target="#invoiceModal"
                >
                  Generate Invoice
                </button>
              ) : (
                <button className="button button-primary">
                  Wait while seller Generate a Invoice
                </button>
              )}
            </div>
            <div className="company-detail purchase-order">
              <div className="flex-box">
                <div className="flex-item">
                  <strong>PO ID: #{ShortOrderDetails?.po_id}</strong>
                </div>
              </div>
              <div className="order-table">
                <span className="order-id">
                  <strong>Order ID #{ShortOrderDetails?.id}</strong>
                </span>
                {!loading && products?.length ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Sr NO</th>
                        <th>Product Category, Product Sub Category</th>
                        <th>Product Differentiation</th>
                        <th>Required Qty</th>
                        <th>GST (%)</th>
                        <th>Seller Offer Price per Qty</th>
                        <th
                          colSpan={`${
                            ShortOrderDetails?.status === "temporary" ||
                            ShortOrderDetails?.status === "draft"
                              ? 2
                              : 3
                          }`}
                        >
                          Total Gross Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {products?.map((item, index) => {
                        return (
                          <>
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{`${item?.product?.category?.name}`}</td>
                              <td>
                                {item?.product?.brand?.name
                                  ? `Brand: ${item?.product?.brand?.name},`
                                  : "Brand: N/A,"}
                                &nbsp;
                                {item?.product?.size
                                  ? `Size: ${item?.product?.size},`
                                  : "Size: N/A,"}
                                &nbsp;
                                {item?.product?.color?.name
                                  ? `Color: ${item?.product?.color?.name}`
                                  : "Color: N/A"}
                              </td>
                              <td>{item?.quantity}</td>
                              <td>{item?.gst?.percentage}</td>
                              <td>₹{item?.amount}</td>
                              <td
                                colSpan={`${
                                  ShortOrderDetails?.status === "temporary" ||
                                  ShortOrderDetails?.status === "draft"
                                    ? 2
                                    : 3
                                }`}
                              >
                                ₹{item?.sub_total}
                              </td>
                              {(ShortOrderDetails?.status === "temporary" ||
                                ShortOrderDetails?.status === "draft") && (
                                <td>
                                  <DeleteIcon style={{ cursor: "pointer" }} />
                                </td>
                              )}
                            </tr>
                          </>
                        );
                      })}
                      <tr>
                        <td colSpan={"6"}></td>
                        <td className="height-light">Total</td>
                        <td className="height-light">
                          ₹{ShortOrderDetails?.sub_total}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={"6"}></td>
                        <td className="height-light">
                          {ShortOrderDetails?.freight_charge?.percentage}%
                          Freight
                        </td>
                        <td className="height-light">
                          ₹{ShortOrderDetails?.freight_charge?.charge}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={"6"}></td>
                        <td className="height-light">Total after freight</td>
                        <td className="height-light">
                          ₹
                          {parseFloat(
                            Number(ShortOrderDetails?.freight_charge?.charge) +
                              Number(ShortOrderDetails?.sub_total)
                          )?.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={"6"}></td>
                        <td className="height-light">GST</td>
                        <td className="height-light">
                          ₹{ShortOrderDetails?.gst}
                        </td>
                      </tr>
                      <tr className="footer">
                        <td className="height-light">Profile ID</td>
                        <td className="height-light">
                          {ShortOrderDetails?.user?.id}
                        </td>
                        <td colSpan={"2"} className="height-light">
                          Payment Terms
                        </td>
                        <td colSpan={"2"} className="height-light">
                          {ShortOrderDetails?.payment_type}
                        </td>
                        <td className="height-light">Total Invoice Value</td>
                        <td className="height-light">
                          ₹{ShortOrderDetails?.grand_total}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <Skeleton height={270} />
                )}
              </div>
            </div>
            {!loading && (
              <div className="btn-wrap">
                <Link
                  to={"/product-purchase-order"}
                  state={{
                    id: ShortOrderDetails?.id,
                    route: location?.state?.route,
                  }}
                  className="button button-blue"
                >
                  Previous
                </Link>

                {ShortOrderDetails?.links?.invoice && (
                  <a
                    download="invoice.pdf"
                    href={ShortOrderDetails?.links?.invoice}
                    className="button button-primary download-btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Invoice
                  </a>
                )}

                {Number(ShortOrderDetails?.step?.current_step) >= 4 ? (
                  <Link
                    state={{
                      id: ShortOrderDetails?.id,
                      route: location?.state?.route,
                    }}
                    to={`${
                      payment === "advance"
                        ? "/product-bank-detail"
                        : "/product-e-bill"
                    }`}
                    className="button button-primary"
                  >
                    Next
                  </Link>
                ) : (
                  <button disabled className="button button-secondary">Next</button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <InvoiceModal OrderID={ShortOrderDetails?.id} updateData={updateData} />
    </>
  );
}

export default ProductPurchaseInvoice;
