import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import { toast, ToastContainer } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";

const InvoiceModal = ({ OrderID, updateData }) => {
  const CloseRef = useRef(null);
  const [deliveryTerms, setDeliveryTerms] = useState('')
  const [invoiceData, setInvoiceData] = useState({
    terms_of_delivery: "",
    waybill_number: "",
    docket_number: "",
    vehicle_number: "",
    transport_details: "",
    referenceNumber: "",
  });
  console.log(invoiceData, "---");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditor = (content) => {
    setDeliveryTerms(content);
  };

  const generateInvoice = async () => {
    const formdata = new FormData();
    formdata.append("order_id", OrderID);
    formdata.append("terms_of_delivery", deliveryTerms);
    formdata.append("way_bill_number", invoiceData.waybill_number);
    formdata.append("docket_number", invoiceData.docket_number);
    formdata.append("vehicle_number", invoiceData.vehicle_number);
    formdata.append("transporter", invoiceData.transport_details);
    formdata.append("reference_no", invoiceData.referenceNumber);

    try {
      await post("/short_order/update/invoice", formdata, getAuthConfig());
      CloseRef.current.click();
      updateData();
    } catch (error) {
      CloseRef.current.click();
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <ToastContainer />

      <div
        className="modal fade post-social-modal"
        id="invoiceModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="invoiceModal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Invoice Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div class="col-sm">
                  <div className="form-field">
                    <label className="form-label">Terms of Delivery</label>
                    <Editor
                      name="terms_of_delivery"
                      apiKey="5ti6dhxcp0v40nnj6xwi874c19ixiq2qtqucc2zlvfxxxs8j"
                      value={deliveryTerms}
                      init={{
                        height: 200,
                        menubar: false,
                      }}
                      onEditorChange={(content) => handleEditor(content)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">Reference Number</label>
                    <input
                      type="text"
                      name="referenceNumber"
                      id="referenceNumber"
                      className="form-input"
                      placeholder="Reference Number"
                      value={invoiceData.referenceNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">Waybill Number</label>
                    <input
                      type="text"
                      name="waybill_number"
                      id="waybill_number"
                      className="form-input"
                      placeholder="WayBill Number"
                      value={invoiceData.waybill_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">Docket Number</label>
                    <input
                      type="text"
                      name="docket_number"
                      id="docket_number"
                      className="form-input"
                      placeholder="Docket Number"
                      value={invoiceData.docket_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">
                      Vehicle Number (GJ01BL4567)
                    </label>
                    <input
                      type="text"
                      name="vehicle_number"
                      id="vehicle_number"
                      className="form-input"
                      placeholder="Vehicle Number"
                      value={invoiceData.vehicle_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-field">
                    <label className="form-label">Transport Details/Name</label>
                    <input
                      type="text"
                      name="transport_details"
                      id="transport_details"
                      className="form-input"
                      placeholder="Transport Details/Name"
                      value={invoiceData.transport_details}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer text-center">
              <button
                className="button button-primary"
                onClick={generateInvoice}
              >
                Generate Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceModal;
