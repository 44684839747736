import React, { useEffect, useState } from "react";
import "../common/scss/pages/short-order.scss";
import Header from "../common/header";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import { ReactComponent as LinkIcon } from "../assets/images/link-icon.svg";
import RejectedOfferIcon from "../assets/images/rejeted-offer.svg";
import { ReactComponent as OfferSentIcon } from "../assets/images/offer-sent-icon.svg";
import DraftOfferIcon from "../assets/images/draft-offer.svg";
import InitialOfferReceivedIcon from "../assets/images/initial-offer-received.svg";
import OfferReceivedIcon from "../assets/images/reviced-offer-request.svg";
import RequestedOfferIcon from "../assets/images/requested-offer.svg";
import { ReactComponent as SendOfferIcon } from "../assets/images/send-offer.svg";
import { ReactComponent as AcceptIcon } from "../assets/images/offer-accept.svg";
import { ReactComponent as RejectIcon } from "../assets/images/short-reject.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { useCallback } from "react";
import { useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Auth from "../libs/auth";
import { PuffLoader } from "react-spinners";

function NegotiationBuyer() {
  const user = Auth.getCurrentUser();
  const [orderListing, setOrderListing] = useState({
    has_more: false,
    items: [],
  });
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState("latest");
  const [filtering, setFiltering] = useState(
    location?.state?.filtering ? location?.state?.filtering : "all"
  );
  const [filterType, setFilterType] = useState("");
  const [searching, setSearching] = useState("");
  const [productListing, setProductListing] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = (action) => {
    setPage(0);
    setFiltering(action);
  };

  const handleTypeClick = (action) => {
    setPage(0);
    setFilterType(action);
  };

  const searchShortOrder = (e) => {
    setSearching(e.target.value);
  };

  const shortOrderListing = async () => {
    setLoading(true);
    if (searching === "") {
      await get(
        `/negotiation/listing?page=${
          page + 1
        }&type=${filtering}&order_by=${sorting}&from=${filterType}&search=`,
        getAuthConfig()
      )
        .then((res) => {
          setLoading(false);

          if (page === 0) {
            setOrderListing((p) => ({ ...p, items: res?.data?.data?.data }));
          } else {
            setOrderListing((p) => ({
              ...p,
              items: orderListing.items.concat(res?.data?.data?.data),
            }));
          }
          setProductListing(
            res?.data?.data?.data?.map((item) => item.products[0])
          );
          if (res?.data?.data?.has_more === true) {
            setOrderListing((p) => ({ ...p, has_more: true }));
          } else {
            setOrderListing((p) => ({ ...p, has_more: false }));
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      await get(
        `/negotiation/listing?page=${
          page + 1
        }&type=${filtering}&order_by=${sorting}&search=${searching}&from=${filterType}`,
        getAuthConfig()
      )
        .then((res) => {
          setLoading(false);
          if (page === 0) {
            setOrderListing((p) => ({
              ...p,
              items: res?.data?.data?.data,
            }));
          } else {
            setOrderListing((p) => ({
              ...p,
              items: orderListing.items.concat(res?.data?.data?.data),
            }));
          }
          setProductListing(
            res?.data?.data?.data?.map((item) => item.products[0])
          );
          if (res?.data?.data?.has_more === true) {
            setOrderListing((p) => ({ ...p, has_more: true }));
          } else {
            setOrderListing((p) => ({ ...p, has_more: false }));
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      shortOrderListing();
    }, 1000);
  }, [sorting, filtering, searching, filterType]);

  useEffect(() => {
    if (page >= 1) {
      shortOrderListing();
    }
  }, [page]);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && orderListing?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [orderListing?.has_more]
  );

  const getReleaseorder = async (id) => {
    await get(`/negotiation/release-short-order/${id}`, getAuthConfig())
      .then((res) => {
        // localStorage.setItem("SOID", res?.data?.data?.id);
      })
      .catch((err) => {});
  };

  const handleAcceptOrReject = async (action, id, index) => {
    const formData = new FormData();
    formData.append("type", action);
    formData.append("negotiation_id", id);
    await post("/negotiation/action", formData, getAuthConfig())
      .then((res) => {
        let arr = [];
        arr = orderListing?.items;

        if (action === "reject") {
          toast.success("Offer Rejected Successfully ");
          arr[index].status = "rejected";
        }
        if (action === "accept") {
          getReleaseorder(id);
          toast.success("Offer Accepted Successfully ");

          arr[index].status = "accepted";
        }
        setOrderListing((p) => ({ ...p, items: arr }));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleNegotiationClick = (id) => {
    navigate(`/negotiation-buyer-adjustmentView-post/${id}`, {
      state: { id },
    });
  };

  const onTop = () => {
    window.scrollTo(0, 0);
  };
  UseEffectOnce(() => {
    onTop();
  }, [location]);

  const parentToChild = (slug) => {
    navigate("/negotiation-seller");
    // alert("hello ")
    // setLoading(true);

    // if (user?.id === companyData?.id) {
    //   get(`/user/profile/details/${slug}`, {
    //     headers: { Authorization: `Bearer ${user.token}` },
    //   })
    //     .then((response) => {
    //       setCompanyData(response?.data?.data);

    //         setLoading(false);

    //     })
    //     .catch((e) => {
    //       alert("this is error", e);
    //     });
    // }
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
  }

  useEffect(() => {
    if (
      location?.state?.filtering != undefined ||
      location?.state?.filtering != null
    ) {
      handleClick(location?.state?.filtering);
    }
  }, [location?.state?.filtering]);
  return (
    <>
      <ToastContainer />

      <Header home parentToChild={parentToChild} />

      <div className="grey-bg">
        <div className="container-fluid support-page">
          <div className="short-order">
            <div className="short-order-head">
              <div className="page-title">
                <h6>Negotiations</h6>
              </div>
              <div className="order-filter">
                <div className="tabs tabs--solid">
                  <ul>
                    <li
                      className={`${filterType === "all" ? "active" : ""}`}
                      onClick={() => handleTypeClick("all")}
                    >
                      <Link to={""}>All</Link>
                    </li>
                    <li
                      className={`${filterType === "post" ? "active" : ""}`}
                      onClick={() => handleTypeClick("post")}
                    >
                      <Link to={""}>From Post</Link>
                    </li>
                    <li
                      className={`${
                        filterType === "portfolio" ? "active" : ""
                      }`}
                      onClick={() => handleTypeClick("portfolio")}
                    >
                      <Link to={""}>From Portfolio</Link>
                    </li>
                  </ul>
                </div>
                <div className="search-box">
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Search"
                    value={searching}
                    onChange={searchShortOrder}
                    onBlur={(e) => {
                      e.preventDefault();
                      clearInterval();
                    }}
                  />
                  <button className="search-button">
                    <SearchIcon />
                  </button>
                </div>
              </div>
              <div className="short">
                <select
                  className="form-input"
                  value={sorting}
                  onChange={(e) => setSorting(e.target.value)}
                >
                  <option value="">Sort By</option>
                  <option value="latest">Newest</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>
            </div>
            <div className="tabs tabs--transparent">
              <ul>
                <li
                  className={`${filtering === "all" ? "active" : ""}`}
                  onClick={() => handleClick("all")}
                >
                  <Link to={""}>All</Link>
                </li>
                <li
                  className={`${
                    filtering === "initial_offer_received" ? "active" : ""
                  }`}
                  onClick={() => handleClick("initial_offer_received")}
                >
                  <Link to={""}>Initial Offer Received</Link>
                </li>
                <li
                  className={`${
                    filtering === "revised_offer_sent" ? "active" : ""
                  }`}
                  onClick={() => handleClick("revised_offer_sent")}
                >
                  <Link to={""}>Revised Offer Requested</Link>
                </li>
                <li
                  className={`${
                    filtering === "final_offer_received" ? "active" : ""
                  }`}
                  onClick={() => handleClick("final_offer_received")}
                >
                  <Link to={""}>Final Offer Sent / Received</Link>
                </li>
                <li
                  className={`${filtering === "rejected" ? "active" : ""}`}
                  onClick={() => handleClick("rejected")}
                >
                  <Link to={""}>Rejected offer</Link>
                </li>
                <li
                  className={`${filtering === "draft" ? "active" : ""}`}
                  onClick={() => handleClick("draft")}
                >
                  <Link to={""}>Drafts</Link>
                </li>
              </ul>
            </div>
            {loading && (
              <div className="d-flex justify-content-center">
                <PuffLoader size={100} />
              </div>
            )}

            {!loading && orderListing?.items?.length === 0 && (
              <div className="text-center">No records found</div>
            )}

            {orderListing?.items &&
              orderListing?.items?.map((item, iindex) => (
                <div className="orders" ref={lastBookElementRef} key={item}>
                  <div className="order-item">
                    <div className="image-block">
                      <img alt="" src={item?.products[0]?.thumb_image?.file} />
                    </div>
                    <div className="content-block">
                      <div className="left">
                        <h6>
                          {item?.products[0]?.name}{" "}
                          {item?.status === "draft" && (
                            <Link
                              to={`/negotiation-buyer-Draft/${item?.id}`}
                              state={{ id: item?.id }}
                            >
                              {item?.products?.length > 1
                                ? ` & view ${item?.products?.length - 1} more `
                                : ""}
                            </Link>
                          )}
                          {item?.type === "post_to_buy" && (
                            <Link
                              to={`/negotiation-buyer-adjustmentView-post/${item?.id}`}
                              state={{ id: item?.id }}
                            >
                              {item?.products?.length > 1
                                ? ` & view ${item?.products?.length - 1} more `
                                : ""}
                            </Link>
                          )}
                          {item?.type === "post_to_sell" ? (
                            item?.to_user_id === user?.id ? (
                              <Link
                                to={"/deal-buyer"}
                                state={{ negotiationId: item?.id }}
                              >
                                {item?.products?.length > 1
                                  ? ` & view ${
                                      item?.products?.length - 1
                                    } more `
                                  : ""}
                              </Link>
                            ) : (
                              <Link
                                to={"/deal-seller"}
                                state={{ negotiationId: item?.id }}
                              >
                                {item?.products?.length > 1
                                  ? ` & view ${
                                      item?.products?.length - 1
                                    } more `
                                  : ""}
                              </Link>
                            )
                          ) : (
                            <Link
                              to={`/negotiation-buyer-adjustmentView/${item?.id}`}
                              state={{ id: item?.id }}
                            >
                              {item?.products?.length > 1
                                ? ` & view ${item?.products?.length - 1} more `
                                : ""}
                            </Link>
                          )}
                        </h6>
                        <div className="company-name">
                          <img
                            alt=""
                            src={
                              user.id === item?.to_user?.id
                                ? item?.user?.company?.logo
                                : item?.to_user?.company?.logo
                            }
                          />
                          {user.id === item?.to_user?.id
                            ? item?.user?.company?.name
                            : item?.to_user?.company?.name}
                        </div>
                        <ul>
                          <li>
                            <label>Description</label>:
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item?.products[0]?.description
                                  ? item?.products[0]?.description
                                  : "N/A",
                              }}
                            ></span>
                          </li>
                          <li>
                            <label>Payment Terms</label>:
                            <span>{item?.payment_type}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="right">
                        {item.status === "rejected" && (
                          <div className="time color-red">
                            <span>Rejected Date: </span>
                            {convert(item?.created_at)}
                          </div>
                        )}
                        {item.status === "revise_offer" && (
                          <div className="status">
                            <img
                              src={InitialOfferReceivedIcon}
                              alt="initialOfferIcon"
                            />
                            <span className="status-label">
                              Revised offer sent
                            </span>
                          </div>
                        )}
                        {item.status === "revised" && (
                          <div className="status">
                            <img src={OfferReceivedIcon} alt="OfferIcon" />

                            <span className="status-label">
                              Final Offer Sent
                            </span>
                          </div>
                        )}
                        {item.status === "final_offer" && (
                          <div className="status">
                            <img src={OfferReceivedIcon} alt="OfferIcon" />

                            <span className="status-label">
                              Final Offer Received
                            </span>
                          </div>
                        )}
                        {item.status === "draft" && (
                          <div className="status">
                            <img src={DraftOfferIcon} alt="draftIcon" />

                            <span className="status-label">Drafts</span>
                          </div>
                        )}
                        {item.status === "rejected" && (
                          <div className="status">
                            <img src={RejectedOfferIcon} alt="rejected" />
                            <span className="status-label">Rejected Offer</span>
                          </div>
                        )}
                        {item.status === "accepted" && (
                          <div className="status">
                            <AcceptIcon />
                            <span className="status-label">Accepted</span>
                          </div>
                        )}
                        {item.status === "initial_offer" && (
                          <div className="status">
                            <img src={RequestedOfferIcon} alt="requested" />

                            <span className="status-label">
                              Initial offer Received
                            </span>
                          </div>
                        )}

                        {item?.status === "draft" ? (
                          <Link
                            to={`/negotiation-buyer-Draft/${item?.id}`}
                            state={{ id: item?.id }}
                            className="view-btn"
                          >
                            <LinkIcon />
                            View Post
                          </Link>
                        ) : item.type === "post_to_buy" ? (
                          <Link
                            to={`/negotiation-buyer-adjustmentView-post/${item?.id}`}
                            state={{ id: item?.id }}
                            className="view-btn"
                          >
                            <LinkIcon />
                            View Post
                          </Link>
                        ) : item?.type === "post_to_sell" ? (
                          item?.to_user_id === user?.id ? (
                            <Link
                              to={"/deal-buyer"}
                              state={{ negotiationId: item?.id }}
                              className="view-btn"
                            >
                              {" "}
                              <LinkIcon /> View Post
                            </Link>
                          ) : (
                            <Link
                              to={"/deal-seller"}
                              state={{ negotiationId: item?.id }}
                              className="view-btn"
                            >
                              {" "}
                              <LinkIcon /> View Post
                            </Link>
                          )
                        ) : (
                          <Link
                            to={`/negotiation-buyer-adjustmentView/${item?.id}`}
                            state={{ id: item?.id }}
                            className="view-btn"
                          >
                            <LinkIcon />
                            View Post
                          </Link>
                        )}
                        {item.status === "initial_offer" &&
                          item.can_edit === 1 && (
                            <div className="btn-wrap">
                              {item.can_revise_offer === 1 && (
                                <button
                                  className="button button-primary button-primary--bordered"
                                  disabled={item.can_edit === 0 ? true : false}
                                  onClick={() => {
                                    handleNegotiationClick(item.id);
                                  }}
                                >
                                  <SendOfferIcon />
                                  Send Revise Offer
                                </button>
                              )}
                              <button
                                className="button button-green button-green--bordered btn-release"
                                disabled={item.can_edit === 0 ? true : false}
                                onClick={(e) => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Are you sure you want to accept this offer  ?",
                                    icon: "warning",
                                    dangerMode: true,
                                    buttons: ["Cancel", "Ok"],
                                  }).then((willDelete) => {
                                    setLoading(true);
                                    if (willDelete) {
                                      handleAcceptOrReject(
                                        "accept",
                                        item?.id,
                                        iindex
                                      );
                                      setLoading(false);
                                    } else {
                                    }
                                  });
                                }}
                              >
                                <AcceptIcon />
                                Accept
                              </button>
                              <button
                                className="button button-red button-red--bordered btn-reject"
                                disabled={item.can_edit === 0 ? true : false}
                                onClick={(e) => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Are you sure you want to reject this offer  ?",
                                    icon: "warning",
                                    dangerMode: true,
                                    buttons: ["Cancel", "Ok"],
                                  }).then((willDelete) => {
                                    setLoading(true);
                                    if (willDelete) {
                                      handleAcceptOrReject(
                                        "reject",
                                        item?.id,
                                        iindex
                                      );
                                      setLoading(false);
                                    } else {
                                    }
                                  });
                                }}
                              >
                                <RejectIcon />
                                Reject
                              </button>
                            </div>
                          )}
                        {item.status === "draft" && (
                          <div className="btn-wrap">
                            <Link
                              to={`/negotiation-buyer-Draft/${item?.id}`}
                              state={{ id: item?.id }}
                              className="button button-primary"
                            >
                              View Draft
                            </Link>
                          </div>
                        )}
                        {item.status === "final_offer" &&
                          item.can_edit === 1 && (
                            <div className="btn-wrap">
                              <button
                                className="button button-green button-green--bordered btn-release"
                                onClick={(e) => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Are you sure you want to accept this offer  ?",
                                    icon: "warning",
                                    dangerMode: true,
                                    buttons: ["Cancel", "Ok"],
                                  }).then((willDelete) => {
                                    setLoading(true);
                                    if (willDelete) {
                                      handleAcceptOrReject(
                                        "accept",
                                        item?.id,
                                        iindex
                                      );
                                      setLoading(false);
                                    } else {
                                    }
                                  });
                                }}
                              >
                                <AcceptIcon />
                                Release Short Order
                              </button>
                              <button
                                className="button button-red button-red--bordered btn-reject"
                                onClick={(e) => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Are you sure you want to reject this offer  ?",
                                    icon: "warning",
                                    dangerMode: true,
                                    buttons: ["Cancel", "Ok"],
                                  }).then((willDelete) => {
                                    setLoading(true);
                                    if (willDelete) {
                                      handleAcceptOrReject(
                                        "reject",
                                        item?.id,
                                        iindex
                                      );
                                      setLoading(false);
                                    } else {
                                    }
                                  });
                                }}
                              >
                                <RejectIcon />
                                Reject
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="order-item-footer">
                      <ul>
                        <li>
                          Start Date:{" "}
                          {moment(item?.created_at)?.format("Do MMMM YYYY")}
                        </li>
                        <li>QTY: {item?.total_qty}</li>
                        <li>Initial Price: {item?.initial?.grand_total}</li>
                        <li>
                          Revised Offer From Me:
                          {item?.negotiation?.grand_total
                            ? `₹${item?.negotiation?.grand_total}`
                            : " N/A"}{" "}
                        </li>
                        {(item.status === "rejected" ||
                          item.status === "final_offer") && (
                          <li>
                            Final Offer From Seller:{" "}
                            {item?.final_offer?.grand_total
                              ? item?.final_offer?.grand_total
                              : "N/A"}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default NegotiationBuyer;
