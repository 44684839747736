import React from "react";

const ReviewProgressPanel = ({ label, reviews }) => {
  return (
    <div className="progresses">
      <label className="form-label">{label}</label>
      <div className="progresses-bar">
        <div className="progresses-bar--value" style={{ width: `${reviews * 20}%` }}></div>
      </div>
    </div>
  );
};

export default ReviewProgressPanel;
